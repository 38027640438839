import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { isMeasure } from '@/grid/mapping/utils';
import { useSettings } from '@/settings';
import { AggregationStyle, FormulaType } from '@/types/settings';
import { cn } from '@/utils/cn';
import { beautifyExpression, operatorInserter } from '@/utils/functional';
import { getCalculation, validateCalculation } from '@grid/calculation';
import { openIfElseEditor } from '@utils/tableau';
import { useEffect, useMemo, useRef, useState } from 'react';
import TextInput from 'react-autocomplete-input';
import Operators from '../operators';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Label, LabelWithTooltip } from '../ui/label';
import AggregationOptions from './settings/AlignmentOptions';
import ColorOptions from './settings/ColorOptions';
import FormattingOptions from './settings/FormattingOptions';

type FormulaBuilderProps = {
  currentFormulaSettings: FormulaType | null;
  setCurrentFormulaSettings: (formula: FormulaType | null) => void;
  addFormula: (formula: FormulaType) => void;
};

type Column = {
  dataType: string;
  fieldName: string;
  fieldNameWithAgg: string;
  index: number | string;
  fieldId: string;
};

const aggregationStyles: AggregationStyle[] = ['Calc', 'sum', 'min', 'max', 'count', 'CountD', 'avg', 'first', 'last'];

export default function ({ currentFormulaSettings, setCurrentFormulaSettings, addFormula }: FormulaBuilderProps) {
  const { settings } = useSettings('dialog');
  const columns: Column[] = settings.summaryData.columns;

  const [formula, setFormula] = useState<FormulaType>(defaultFormula);
  const formulaInputRef = useRef<any>(null);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [errors, setErrors] = useState(initalErrors);
  const currencySupported = isCurrencySupported(formula.format.currency);

  const hasErrors = useMemo(() => {
    return Object.values(errors).some((error) => error !== '');
  }, [errors]);

  const measureColumns = useMemo(() => {
    return columns.filter(isMeasure);
  }, [columns]);

  useEffect(() => {
    setErrors(initalErrors);
    if (!currentFormulaSettings) {
      clearFormula();
      return;
    }

    disableInput(currentFormulaSettings.calculation);

    const formula = getFormulaObject(currentFormulaSettings);
    setFormula(formula);
  }, [currentFormulaSettings]);

  const _addFormula = () => {
    const errors = { ...initalErrors };
    if (!formula.fieldName) {
      errors.name = 'Name is required';
    }
    if (!formula.calculation) {
      errors.expression = 'Expression is required';
    } else if (
      (formula.calculation.includes(`'`) || formula.calculation.includes(`"`) || formula.calculation.includes('`')) &&
      !inputDisabled // if input is disabled, it means we are editing an if else expression
    ) {
      errors.expression = 'Expression cannot contain quotes. Strings are not supported.';
    }

    if (formula.format.style === 'currency' && !currencySupported) {
      errors.currency = 'Please use a valid currency. Eg: USD, EUR.';
    }
    if (errors.name || errors.expression || errors.currency) {
      setErrors(errors);
      return;
    }

    if (currentFormulaSettings) {
      formula.index = currentFormulaSettings.index;
    }
    addFormula(formula);
    clearFormula();
  };

  const disableInput = (calculation: string) => {
    const [isIfElse, variable] = getCalculation(calculation);
    if (!isIfElse || !variable) return;

    setInputDisabled(true);
  };

  const _openIfElseEditor = async () => {
    const data = {
      allColumns: columns.map((col) => col.fieldNameWithAgg),
      measureColumns: measureColumns.map((col) => col.fieldNameWithAgg),
      givenExpression: formula.calculation,
    };
    const dataString = JSON.stringify(data);
    const statement = await openIfElseEditor(dataString);
    setErrors((e) => ({ ...e, expression: '' }));
    if (!statement) {
      setInputDisabled(false);
      return;
    } else if (statement === 'closedByUser') {
      return;
    } else if (statement === 'cancel') {
      return;
    } else if (statement === 'alreadyOpen') {
      setTimeout(() => {
        setErrors((er) => ({ ...er, ifElse: '' }));
      }, 3000);
      setErrors((er) => ({ ...er, ifElse: 'If Else Editor is already open' }));
      return;
    }
    setFormula((f) => ({ ...f, calculation: statement }));
    setInputDisabled(true);
    const err = validateCalculation({ calculation: statement }, columns);
    if (err) {
      setErrors((e) => ({ ...e, expression: err }));
      return;
    }
  };

  const setTextInFormula = (operator: string) => {
    const textInput = formulaInputRef?.current?.refInput;
    const start = textInput?.current?.selectionStart;
    const end = textInput?.current?.selectionEnd;
    const insertOperator = operatorInserter(operator, start, end);

    setFormula((f) => ({ ...f, calculation: insertOperator(f.calculation) }));

    // Set focus and cursor position after the new characters
    setTimeout(() => {
      const input = textInput?.current;
      if (input) {
        input.focus();
        input.setSelectionRange(end + operator.length, end + operator.length);
      }
    }, 0);
  };

  const clearFormula = () => {
    setFormula(defaultFormula);
    setCurrentFormulaSettings(null);
    setInputDisabled(false);
    setErrors(initalErrors);
  };

  const onFormulaChange = (calc: string) => {
    setFormula((f) => ({ ...f, calculation: calc }));
    try {
      const err = validateCalculation({ calculation: calc }, columns);
      if (err) {
        setErrors((e) => ({ ...e, expression: err }));
        return;
      }
    } catch (err) {
      setErrors((e) => ({ ...e, expression: '' + err }));
      return;
    }
    if (errors.expression) setErrors((e) => ({ ...e, expression: '' }));
  };

  return (
    <div className="flex flex-1 flex-col justify-between gap-2 overflow-hidden text-black">
      <div className="flex flex-col gap-2 overflow-y-auto">
        <h2 className="p-2 text-xl">Formula editor</h2>

        <div className="flex flex-1 flex-col gap-4 px-2 pb-4">
          <div className="flex flex-col gap-4">
            <div className="">
              <LabelWithTooltip tooltip="The name of the formula field. This will be the name of the column.">
                Formula Field Name
              </LabelWithTooltip>
              <Input
                type="text"
                value={formula.fieldName}
                onChange={(e: { target: { value: string } }) => {
                  if (errors.name) setErrors((e) => ({ ...e, name: '' }));
                  setFormula((f) => ({ ...f, fieldName: e.target.value }));
                }}
                required
              />
              {errors.name && <div className="text-red-500">{errors.name}</div>}
            </div>

            <div className="flex flex-col gap-2">
              {errors.ifElse && <div className="break-all text-red-500">{errors.ifElse}</div>}
              <div className="flex items-end justify-between">
                <Label>Formula</Label>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={_openIfElseEditor}>
                  If-Else Formula Editor
                </Button>
              </div>
              <div className="flex w-full flex-col items-start rounded-md border border-gray-500">
                <div className="w-full border-b border-gray-500 px-2 py-3">
                  <div className="mr-auto w-fit">
                    <Operators selectedText={setTextInFormula} />
                  </div>
                </div>
                <div className="flex w-full flex-col items-start gap-2">
                  <TextInput
                    disabled={inputDisabled}
                    ref={formulaInputRef}
                    value={beautifyExpression(formula.calculation)}
                    required
                    trigger="@"
                    placeholder="Eg: (column1 + column2) * 100"
                    matchAny={true}
                    options={measureColumns.map((col) => col.fieldNameWithAgg)}
                    onChange={onFormulaChange}
                    changeOnSelect={(trigger: any, slug: any) => {
                      return slug;
                    }}
                    className={cn(
                      'm-0 min-h-32 w-full rounded-md p-3 outline-none',
                      inputDisabled && 'cursor-not-allowed text-gray-500'
                    )}
                  />
                </div>
              </div>

              {errors.expression && <div className="text-red-500">{errors.expression}</div>}
              <div className="text-grey-500 opacity-70">
                {inputDisabled
                  ? 'Use the If-Else Formula Editor to edit this formula'
                  : "Use '@' to get a dropdown of available columns"}
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-1">
            <Label>Aggregation type</Label>
            <Select
              onValueChange={(value: AggregationStyle) =>
                setFormula((f: FormulaType) => ({ ...f, grouping: { ...f.grouping, aggFunc: value } }))
              }
              value={formula.grouping.aggFunc}>
              <SelectTrigger>
                <SelectValue placeholder="Select aggregation" />
              </SelectTrigger>
              <SelectContent>
                {aggregationStyles.map((styleOption) => (
                  <SelectItem
                    key={styleOption}
                    value={styleOption}>
                    {styleOption.charAt(0).toUpperCase() + styleOption.slice(1)}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <AggregationOptions
            formula={formula}
            setFormula={setFormula}
          />

          <FormattingOptions
            formula={formula}
            setFormula={setFormula}
            setErrors={setErrors}
            currencySupported={currencySupported}
          />
          {errors.currency && <div className="w-fit-content mx-auto text-red-500">{errors.currency}</div>}

          <ColorOptions
            formula={formula}
            setFormula={setFormula}
          />
        </div>
      </div>

      <div className="z-10 flex items-center justify-end gap-4 rounded p-3 shadow-custom-shadow-top">
        <Button
          variant="outline"
          type="reset"
          onClick={() => clearFormula()}>
          Clear
        </Button>
        <Button
          onClick={() => _addFormula()}
          type="submit"
          disabled={hasErrors}>
          {currentFormulaSettings ? 'Modify' : 'Add'}
        </Button>
      </div>
    </div>
  );
}

function isCurrencySupported(currency: string | undefined) {
  if (!currency) return false;
  try {
    let format = new Intl.NumberFormat(undefined, { style: 'currency', currency: currency });
    format.format(0);
    return true;
  } catch (error) {
    return false;
  }
}

const initalErrors = {
  name: '',
  expression: '',
  ifElse: '',
  currency: '',
};

const defaultFormula: FormulaType = {
  fieldName: '',
  calculation: '',
  index: '',
  dataType: 'float',
  format: {
    style: 'decimal',
    maximumFractionDigits: '2',
    prefix: '',
    suffix: '',
    currency: '',
    nullReplaceString: '',
    positiveValueFormat: 'plus',
    negativeValueFormat: 'minus',
    kSeparator: '',
    mSeparator: '',
    bSeparator: '',
    tSeparator: '',
    useGrouping: false,
  },
  grouping: {
    defaultAggregation: 'Calc',
    aggFunc: 'Calc',
  },
  alignment: 'right',
  headerAlignment: 'left',
  color: {
    type: 'continuous',
  },
};

function getFormulaObject(currentFormulaSettings: FormulaType) {
  const { calculation, fieldName, format, grouping, headerAlignment, alignment, color } = currentFormulaSettings;

  const formula: FormulaType = {
    fieldName,
    calculation,
    index: currentFormulaSettings.index,
    dataType: 'float',
    format: {
      style: format?.style ?? 'decimal',
      maximumFractionDigits: format?.maximumFractionDigits ?? '2',
      prefix: format?.prefix ?? '',
      suffix: format?.suffix ?? '',
      currency: format?.currency ?? '',
      nullReplaceString: format?.nullReplaceString ?? '',
      positiveValueFormat: format?.positiveValueFormat ?? 'plus',
      negativeValueFormat: format?.negativeValueFormat ?? 'minus',
      kSeparator: format?.kSeparator ?? '',
      mSeparator: format?.mSeparator ?? '',
      bSeparator: format?.bSeparator ?? '',
      tSeparator: format?.tSeparator ?? '',
      useGrouping: format?.useGrouping ?? false,
    },
    grouping: {
      defaultAggregation: grouping?.defaultAggregation ?? 'Calc',
      aggFunc: grouping?.aggFunc ?? 'Calc',
    },
    headerAlignment: headerAlignment ?? 'left',
    alignment: alignment ?? 'right',
    color: {
      type: 'continuous',
      backgroundColor: color?.backgroundColor ?? '',
      fontColor: color?.fontColor ?? '',
      headerBackgroundColor: color?.headerBackgroundColor ?? '',
      headerFontColor: color?.headerFontColor ?? '',
    },
  };

  return formula;
}
