import { debounce } from '@/utils/functional';
import { IStatusPanelComp, IStatusPanelParams } from 'ag-grid-community';

export class PivotStatusBarComponent implements IStatusPanelComp {
  params!: IStatusPanelParams;
  eGui!: HTMLDivElement;
  totalRowsValue!: HTMLSpanElement;
  filteredRowsValue!: HTMLSpanElement;
  debouncedUpdateFunctions: { [key: string]: Function } = {}; // Store debounced functions

  init(params: IStatusPanelParams) {
    this.params = params;

    this.eGui = document.createElement('div');
    this.eGui.className = 'pivot-row-status-bar';

    const totalRowsDiv = this.createStatusDiv('Total Pivot Rows: ');
    this.totalRowsValue = totalRowsDiv.querySelector('.ag-status-name-value-value') as HTMLSpanElement;

    const filteredRowsDiv = this.createStatusDiv('Filtered Pivot Rows: ');
    this.filteredRowsValue = filteredRowsDiv.querySelector('.ag-status-name-value-value') as HTMLSpanElement;

    this.eGui.appendChild(totalRowsDiv);
    this.eGui.appendChild(filteredRowsDiv);

    const events = [
      'columnPivotModeChanged',
      'filterChanged',
      'columnRowGroupChanged',
      'columnPivotChanged',
      'gridReady',
    ];
    events.forEach((event) => {
      this.debouncedUpdateFunctions[event] = debounce(this.updatePivotRowInfo.bind(this), 100);
      params.api.addEventListener(event, this.debouncedUpdateFunctions[event]);
    });
  }

  createStatusDiv(labelText: string) {
    const container = document.createElement('div');
    container.className = 'ag-status-name-value';

    const label = document.createElement('span');
    label.innerText = labelText;
    label.style.marginRight = '3px';
    container.appendChild(label);

    const value = document.createElement('span');
    value.className = 'ag-status-name-value-value';
    container.appendChild(value);

    return container;
  }

  getGui() {
    return this.eGui;
  }

  destroy() {
    const events = Object.keys(this.debouncedUpdateFunctions);
    events.forEach((event) => {
      this.params?.api.removeEventListener(event, this.debouncedUpdateFunctions[event]);
    });
  }

  updatePivotRowInfo() {
    const isPivotMode = this.params.api.isPivotMode();
    this.eGui.style.display = isPivotMode ? 'flex' : 'none';

    if (!isPivotMode) return;

    let totalRowCount = 0;
    let filteredRowCount = 0;

    this.params.api.forEachNode((node) => {
      if (node.group) totalRowCount++;
    });

    this.params.api.forEachNodeAfterFilter((node) => {
      if (node.group) filteredRowCount++;
    });

    this.totalRowsValue.innerText = `${totalRowCount}`;
    this.filteredRowsValue.innerText = `${filteredRowCount}`;
  }
}
