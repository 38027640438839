import { gridApi } from '@/grid';
import { Settings, getSettings } from '@/settings';
import { FormulaType } from '@/types/settings';
import { gridOptions } from '@grid/options';
import { EventBus } from '@utils/event-bus';
import { getGridState, storeGridState } from '@utils/grid-state';
import { useEffect, useState } from 'react';
import { FaChevronDown, FaTrash } from 'react-icons/fa';
import { MdModeEdit } from 'react-icons/md';
import { PiSigmaBold } from 'react-icons/pi';
import { Separator } from '../ui/separator';
import FormulaBuilder from './FormulaBuilder';

const FormulasPanel = (): JSX.Element => {
  const settings = getSettings() as Settings;
  const { stateParameter } = settings;
  const [currentFormulaSettings, setCurrentFormulaSettings] = useState<FormulaType | null>(null);
  const [formulasOpen, setFormulasOpen] = useState(false);
  const [formulaColumns, setFormulaColumns] = useState<FormulaType[]>([]);
  const isVizExtension = !!window.tableau.extensions.worksheetContent;

  useEffect(() => {
    getFormulaColumns();
  }, [gridOptions]);

  const getFormulaColumns = async () => {
    let state = await getGridState(gridApi!, stateParameter!);
    setFormulaColumns(state.formulaColumns ?? []);
  };

  const addFormula = async (formula: FormulaType) => {
    if (currentFormulaSettings) {
      // update existing formula
      let state = await getGridState(gridApi!, stateParameter!);
      state.formulaColumns =
        state?.formulaColumns?.map((col) => {
          if (col.index === currentFormulaSettings.index) {
            col = formula;
          }
          return col;
        }) ?? [];

      state.columnState =
        state.columnState?.map((col) => {
          const formulaCol = state.formulaColumns?.find((fCol) => fCol.index === col.colId);
          if (formulaCol) col.aggFunc = formulaCol?.grouping?.aggFunc ?? col.aggFunc;
          return col;
        }) ?? [];

      await storeGridState(state, stateParameter);
      setFormulaColumns(state.formulaColumns);
    } else {
      // adding a new formula
      const index = `calc_${Date.now()}`;
      formula.index = index;

      let state = await getGridState(gridApi!, stateParameter!);
      if (state.formulaColumns) {
        state.formulaColumns.push(formula);
      } else {
        state.formulaColumns = [formula];
      }
      await storeGridState(state, stateParameter);
      setFormulaColumns(state.formulaColumns);
    }
    EventBus.triggerEvent('updateGrid');
  };

  const removeFormula = async (col: FormulaType) => {
    const state = await getGridState(gridApi!, stateParameter!);
    state.formulaColumns = state?.formulaColumns?.filter((formula) => formula.index !== col.index);
    await storeGridState(state, stateParameter);
    setFormulaColumns(state.formulaColumns || []);
    EventBus.triggerEvent('updateGrid');
  };

  let isAuthoring = window.tableau.extensions.environment.mode === 'authoring';
  return (
    <div className="mx-auto flex h-full max-h-full w-full flex-col">
      {!stateParameter && (
        <div className="mx-auto w-fit break-words p-2 text-center text-red-600">
          <br />
          {isAuthoring ? (
            <span>
              Formulas are stored in the Parameter State. <br />
              Please choose a parameter for the option 'Store table layout' in the configuration under the 'General'
              Tab.
            </span>
          ) : (
            <span>
              Formulas are not enabled. <br />
              Please contact your Dashboard Designer.
            </span>
          )}
        </div>
      )}

      {formulaColumns?.length > 0 && (
        <>
          <div className="flex flex-col gap-2 border-b border-gray-400">
            <div
              className="flex cursor-pointer items-center justify-between gap-2 bg-gray-200 p-2 hover:bg-gray-100"
              onClick={() => setFormulasOpen((o) => !o)}>
              <div className="grid place-items-center">
                <PiSigmaBold size={17} />
              </div>
              <div className="flex-1">Existing Formulas</div>
              <div className={`mr-2 ${formulasOpen ? 'rotate-180' : ''}`}>
                <FaChevronDown size={13} />
              </div>
            </div>
            {formulasOpen && (
              <div className="mb-1 flex flex-col gap-1.5 p-2">
                {formulaColumns.map((col) => (
                  <div
                    key={col.index}
                    className={`flex cursor-pointer items-center justify-between gap-2 rounded border border-primary p-1 font-bold ${currentFormulaSettings?.index === col.index ? 'bg-primary text-white' : 'transition-all hover:bg-primary-hover hover:text-white'}`}
                    onClick={() =>
                      setCurrentFormulaSettings((currentCol) => (currentCol?.index === col.index ? null : col))
                    }>
                    <div
                      title="Edit Formula"
                      className="grid place-items-center">
                      <MdModeEdit size={16} />
                    </div>
                    <div
                      className="flex-1 text-base"
                      title="Edit Formula">
                      {col.fieldName}
                    </div>
                    <div
                      className="grid cursor-pointer place-items-center p-1 hover:bg-red-500 hover:text-white"
                      onClick={(e) => {
                        e.stopPropagation();
                        removeFormula(col);
                      }}
                      title="Remove Formula">
                      <FaTrash size={13} />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <Separator />
        </>
      )}

      {stateParameter && (
        <FormulaBuilder
          currentFormulaSettings={currentFormulaSettings}
          setCurrentFormulaSettings={setCurrentFormulaSettings}
          addFormula={addFormula}
        />
      )}
    </div>
  );
};

export default FormulasPanel;
