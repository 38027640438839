// @ts-ignore
import 'ag-grid-enterprise';
import 'whatwg-fetch';
// import 'ag-grid-enterprise/chartsModule';
import type { Column, DataValue, Parameter } from '@tableau/extensions-api-types';
import { CellStyle, ColDef, ColGroupDef, ColumnState, RowNode } from 'ag-grid-community';
import { gridApi, setGridApi } from './grid';
import TooltipGrid, { Tooltip } from './grid/Tooltip';
import { GridAction, applyActions } from './grid/actions';
import {
  GridState,
  hideLoadingProgress,
  initialseFormulaError,
  setLoadingProgress,
  showAGGrid,
  showAlert,
  showConfigure,
  showError,
  showLanding,
  showLoading,
  showLoadingProgress,
} from './grid/interface';
import { createColumnMapper } from './grid/mapping';
import { getField } from './grid/mapping/utils';
import { createValueParser } from './grid/mapping/valueParser';
import { gridOptions } from './grid/options';
import { applySettingsToGridOptions } from './grid/options/applySettingsToGrid';
import { initializeApp } from './main';
import { Settings, parseSettings, shouldIgnoreSettingsChangedEvent } from './settings';
import { ConfigMap } from './types/settings';
import { EventBus } from './utils/event-bus';
import { immediateThenDebounce, removeWhiteSpaces } from './utils/functional';
import { ensureLicenseKey, importLocale } from './utils/grid';
import { getGridState, storeGridState } from './utils/grid-state';
import {
  FETCH_METHODS,
  findParameterAsync,
  getEncodingMap,
  getParametersAsync,
  getSummaryDataAsync,
  removeAgg,
} from './utils/tableau';

const extension = window.tableau.extensions;
const TableauEventType = window.tableau.TableauEventType;

let ignoreParameterChanges: Array<string> = [];
let prevActions: Array<GridAction> | undefined;
let unregisterFns: Array<() => void> = [];

let isStreaming = false;

/**
 * setup rebuilds ag grid from the tableau extension settings.
 * This function should be called whenever the extension settings change.
 */
export default async function (reason?: 'multi-layout-switching' | 'datasheet-switching') {
  console.time('setup');

  if (gridApi) {
    if (shouldIgnoreSettingsChangedEvent()) return;
    gridApi.destroy();
    setGridApi(undefined);
  }

  if (reason === 'multi-layout-switching') {
    gridOptions.context = {
      ...gridOptions.context,
      // for autoAggregation to work
      updateParameterValues: true,
    };
  }

  // make sure we show the loader while we are updating the settings
  showLoading();

  unregisterFns.forEach((unregister) => unregister());

  let maybeSettings: Settings | undefined;
  try {
    await ensureLicenseKey();
    maybeSettings = parseSettings();
  } catch (error: any) {
    console.error("Couldn't parse settings", error);
    showError(error);
    return;
  }

  if (!maybeSettings) {
    showConfigure();
    return;
  }

  // make ts happy
  let settings = maybeSettings;

  const {
    datasheet,
    columnConfig,
    columnGroupConfig,
    calculatedColumns,
    gridState,
    stateParameter,
    theme,
    showCompactMode,
    useTableauFont,
    useAccessibilityMode,
    actions,
    fetchMethod,
    tooltips,
    groupColumnsPanel,
  } = settings;

  Tooltip.init(tooltips, settings);

  // :ignoreActionTargets
  let ignoreActionTargets = {
    worksheet: datasheet.name,
    parameter: stateParameter,
  };

  initialseFormulaError(stateParameter);

  /**
   * Attach grid event listener to update filters on selections
   */
  if (!!extension.worksheetContent || actions.length) {
    const handleSelectionChanged = (rows: Array<RowNode>) => {
      ignoreParameterChanges = applyActions(actions, rows, ignoreActionTargets);
    };

    let unregister = EventBus.addEventListener('selectionChanged', handleSelectionChanged);

    unregisterFns.push(unregister);
  }

  /**
   * Clear any remaining old filters
   */
  // @todo: do we really want to do this?
  if (prevActions) {
    ignoreParameterChanges = applyActions(prevActions, [], ignoreActionTargets);
  }
  prevActions = actions;

  /**
   * Attach grid event listener to update parameter on column changes
   */
  let unregister = EventBus.addEventListener('stateChanged', async () => {
    if (!gridApi || isStreaming) return; // make ts happy
    let state = await getGridState(gridApi, stateParameter ? stateParameter : ''); // make ts happy

    // update the formula column's default aggregation
    if (stateParameter && state.formulaColumns?.length && state.formulaColumns?.length > 0) {
      state.formulaColumns.forEach((formulaColumn) => {
        const agFormulaColumn = gridApi?.getColumn(formulaColumn.index);
        const aggFunc = agFormulaColumn?.getAggFunc();
        formulaColumn.grouping = formulaColumn.grouping;
        if (formulaColumn.grouping) {
          formulaColumn.grouping.defaultAggregation = typeof aggFunc === 'string' ? aggFunc : '';
        }
      });
    }
    storeGridState(state, stateParameter);
  });
  unregisterFns.push(unregister);

  /**
   * Attach grid event listener to update filters on selections
   */
  unregister = EventBus.addEventListener('updateGrid', async () => {
    if (!gridApi || isStreaming) return; // make ts happy
    initializeApp();
  });
  unregisterFns.push(unregister);

  gridOptions.localeText = {
    export: 'Export Table',
    loadingOoo: 'The data is being prepared, one moment please...',
  };

  try {
    gridOptions.localeText = await importLocale();
  } catch (error: any) {
    showError(error);
    return;
  }

  /**
   * Setup configured options
   */
  applySettingsToGridOptions(gridOptions, settings, gridApi);
  type ValueParser = (dataValue: DataValue) => string | number | Date | null; //TODO: CHeck and move to separate file

  let updateColumnDefs = true;
  let currentGridState: GridState = stateParameter ? {} : gridState;
  let prevColumns: Array<Column>;
  let colDataMap: Array<{ index?: number; field: string; parser: ValueParser }> = [{ field: '', parser: () => '' }]; //TODO: Sloppy
  let additionalColumnsRowMap: { [key: string]: any } = {};

  async function update(event?: any) {
    console.time('update');
    console.group('update');

    if (settings.fetchMethod === FETCH_METHODS.All_at_once) hideLoadingProgress();
    else showLoadingProgress();

    if (gridApi) gridApi?.showLoadingOverlay();
    else showLoading();

    try {
      console.time('getSummaryDataAsync');
      //TODO: Check if setloadingprogress is meant to be called
      let { columns, data: rows } = await getSummaryDataAsync(datasheet, settings, setLoadingProgress);
      setLoadingProgress(100);
      console.timeEnd('getSummaryDataAsync');

      const isVizExtension = !!extension.worksheetContent;
      if (isVizExtension) {
        if (!!columns && columns?.length !== 0) {
          const { columnEncoding, encodingMap } = await getEncodingMap(datasheet);
          columns = columns.filter(
            (col) =>
              !(
                columnEncoding[col.fieldId].encodingTypes.length === 1 &&
                columnEncoding[col.fieldId].encodingTypes.includes('tooltip')
              )
          );
        }

        if (!columns || columns.length === 0) {
          if (Object.keys(columnConfig).length === 0) {
            showLanding();
            console.groupEnd();
            console.timeEnd('update');
            return;
          } else {
            // table is configured but no data (maybe due to filters)
            showNoDataOverlay(settings);
          }
        }
      }

      if (rows?.length === 0) {
        // ag-grid has GridAPI.showNoRowsOverlay()
        // it is tricky for us to use this since we only create the Grid
        // after we constructed the columnDefs and rowData and we don't know
        // what columns we have until we get the actual data.
        showNoDataOverlay(settings);
        console.groupEnd();
        console.timeEnd('update');
        return;
      }

      columns?.sort((a, b) => (a.fieldName > b.fieldName ? 1 : b.fieldName > a.fieldName ? -1 : 0));

      // clean columns first (remove AGG for multilang support)
      columns?.forEach(function (arrayItem, index) {
        // @ts-ignore - We want the column name without the aggregation (SUM(ColumnName) -> ColumnName)
        var x = removeAgg(arrayItem._fieldName, arrayItem.dataType);
        // @ts-ignore - We want the column name without the aggregation (SUM(ColumnName) -> ColumnName)
        columns[index]._fieldName = x;
      });

      // determine whether we need to update the column defs
      let isAuthoring = extension.environment.mode === 'authoring';
      if (isAuthoring && updateColumnDefs === false) {
        let columnLengthDiffer = columns?.length !== prevColumns.length;
        let columnFieldsDiffer = columns?.some(function (col, index) {
          // @ts-ignore - We want the column name without the aggregation (SUM(ColumnName) -> ColumnName)
          return col.fieldName !== prevColumns[index]._fieldName;
        });

        updateColumnDefs = (columnLengthDiffer || columnFieldsDiffer) ?? false;
      }
      prevColumns = columns ?? [];

      if (updateColumnDefs) {
        /**
         * Retrieve grid state from parameter
         */
        if (stateParameter) {
          console.time('retrieveGridState');
          let parameter = await datasheet.findParameterAsync(stateParameter);
          if (!parameter) {
            throw new Error(
              `Could not find parameter [${stateParameter}], did you rename or delete the parameter? You can reconfigure the [${stateParameter}] in the configuration -> appearance tab.`
            );
          }

          let value = parameter.currentValue.value;
          if (value) {
            currentGridState = JSON.parse(value);
            // backwards compatibility
            if (Array.isArray(currentGridState)) {
              let columnState = currentGridState;
              currentGridState = { columnState };
            }
          }
          console.timeEnd('retrieveGridState');
        }
        // Disabled because pivot state has colID's with '()'
        // and AGG methods are stored seperately not in the colId

        // // clean AGG methods in the state
        // if (currentGridState.columnState) {
        //   for (let i = 0; i < currentGridState.columnState.length; i++) {
        //     currentGridState.columnState[i].colId = removeAgg(
        //       currentGridState.columnState[i].colId
        //     );
        //   }
        // }

        let newColumnConfig: ConfigMap = {};
        // clean AGG methods in the columnConfig (Tableau settings)
        for (const key in columnConfig) {
          newColumnConfig[removeAgg(key)] = columnConfig[key];
        }
        const formulaColumns = currentGridState?.formulaColumns || [];

        // add formula columns to columnConfig
        if (formulaColumns.length > 0) {
          for (const formulaColumn of formulaColumns) {
            newColumnConfig[formulaColumn.index] = {
              ...newColumnConfig[formulaColumn.index],
              format: formulaColumn.format,
              enableValueAggMenu: true,
              showAggregation: true,
              showDimensionAggregation: true,
              defaultAggregation: formulaColumn?.grouping?.defaultAggregation ?? 'Calc',
              color: formulaColumn.color ?? {},
              colorCellBackground: false,
              alignment: formulaColumn.alignment ?? 'right',
              headerAlignment: formulaColumn.headerAlignment ?? 'left',
            };
          }
        }

        console.time('createColumnDefs');
        const additionalColumns = [...calculatedColumns, ...formulaColumns];
        let allColumns = columns ? [...columns, ...additionalColumns] : [...additionalColumns];
        gridOptions.context = {
          ...gridOptions.context,
          tableauColumns: allColumns,
          newColumnConfig,
        };
        /**
         * Create column defs
         */
        let columnDefs = allColumns.map(createColumnMapper(newColumnConfig, settings));
        // This is so that tooltip has access to all columns
        additionalColumnsRowMap = {};
        for (let col of additionalColumns) {
          let format = {};
          if ('format' in col && col.format) {
            // if format, then it is a column
            format = col.format;
          } else {
            const config = newColumnConfig[col.index];
            format = config?.format || {};
          }
          additionalColumnsRowMap[col.index] = {
            ...col,
            format,
          };
        }

        const verticalAlignment =
          settings.autoRowHeight === true ? 'flex-start' : (settings.verticalAlignment ?? 'flex-start');
        let base: CellStyle = {
          height: '100%',
          display: 'flex',
          alignItems:
            verticalAlignment === 'Bottom' ? 'flex-end' : verticalAlignment === 'Center' ? 'center' : 'flex-start',
        };
        columnDefs.unshift({
          field: 'index',
          headerName: 'Index',
          tooltipField: 'index',
          headerTooltip: 'Index',
          tooltipComponent: TooltipGrid,
          filter: false,
          width: 100,
          suppressSizeToFit: true,
          cellClass: 'exportStyle',
          cellStyle: base,
          autoHeight: false,
          unSortIcon: settings.enableUnSortIcon,
          suppressMovable: !settings.userCanOrderColumns && !isAuthoring,
          resizable: settings.userCanChangeWidth || isAuthoring,
          sortable: settings.userCanSortColumns || isAuthoring,
          suppressColumnsToolPanel: settings.supressIndexColumn,
          cellClassRules: {
            'cell-wrap-text': (params) => {
              return settings.autoRowHeight;
            },
            groupRows: (params) => {
              return params.node.group ?? false;
            },
            borders: (params) => {
              return !params.node.group;
            },
            'total-group-row-cell': function (params) {
              return params.node.level === -1 || params.node?.rowPinned === 'top';
            },
            'subtotal-group-row-cell': function (params) {
              return (params.node.level !== -1 && params.node?.footer && (params.node.group ?? false)) ?? false;
            },
          },
        });

        columnDefs = applyPreviousStateToColumnDefinitions(columnDefs, currentGridState.columnState || []);

        /**
         * Apply column groups
         */
        let groupedColumnDefs: ColDef | Array<ColGroupDef> = [];
        for (let column of columnDefs) {
          if (!column.field) continue; // make ts happy
          let config = newColumnConfig[column.field] || {};
          let header = config.groupHeader || '';

          let path = [header].map((s) => s.trim()).filter(Boolean);
          if (header && (columnGroupConfig[header]?.isMultiLevelGroup ?? true)) {
            path = header
              .split('>')
              .map((s) => s.trim())
              .filter(Boolean);
          }

          let groupId = '';
          let children = groupedColumnDefs;
          // traverse the path and create groups where necessary
          let i = 0;
          for (let headerName of path) {
            groupId += '>' + headerName;
            // @ts-ignore we only care about ColGroupDefs
            let groupItem: ColGroupDef = children.find((col) => col.groupId === groupId);
            if (!groupItem) {
              groupItem = {
                groupId,
                headerName,
                headerClass: `--ag-group-header-${headerName.replace(/[^a-zA-Z0-9-_]/g, '')}`,
                columnGroupShow: i === 0 ? 'closed' : undefined,
                marryChildren: true,
                children: [],
              };
              i++;
              children.push(groupItem);
            }
            if (groupItem && 'children' in groupItem) {
              // @ts-ignore we only care about ColGroupDefs
              children = groupItem.children;
            }
          }
          // found the group that we need to add this column to
          // TODO: redefine types
          // @ts-ignore
          children.push(column);
        }

        // use API if available - not required? Done also in interface.ts
        //@ts-ignore //TODO: if this is actually suppose to behave like this
        if (typeof gridApi?.setColumnDefs === 'function') {
          gridApi.setGridOption('columnDefs', groupedColumnDefs);
        } else {
          gridOptions.columnDefs = groupedColumnDefs;
        }

        // cache the colDataMap between updates this saves us some work
        colDataMap = columns?.map((col) => ({
          index: col.index,
          dataType: col.dataType,
          field: removeAgg(getField(col)),
          parser: createValueParser(col),
        }));

        console.timeEnd('createColumnDefs');
      }

      {
        console.time('createRowData');
        /**
         * Create row data
         */

        /**
         * parseColumnData maps all tableau DataValues to an object that
         * we can use as the rowData for ag-grid.
         */
        let parseColumnData = function (acc: any, cell: DataValue, index: number) {
          let col;
          // @todo: sure hope this index is always the same...
          for (let i = 0; i < colDataMap.length; i++) {
            if (colDataMap[i].index === index) {
              col = colDataMap[i];
              break;
            }
          }

          if (!col) return acc;

          if (settings.groupUseUnbalancedGroups) {
            // fix string values being %null% parsed to null to support unbalanced grouping feature
            if ('_nativeValue' in cell && cell['_value'] === '%null%' && col.dataType === 'string') {
              cell['_value'] = cell['_nativeValue'];
            }
          } else {
            if (
              '_nativeValue' in cell &&
              (cell['_value'] === '%null%' || cell['_value'] === null) &&
              col.dataType === 'string'
            ) {
              cell['_value'] = settings.groupUnbalancedGroupValue ? settings.groupUnbalancedGroupValue : '%null%';
            }
          }

          if (col.dataType === 'string') {
            cell['_value'] = removeWhiteSpaces(cell['_value']);
            cell['_nativeValue'] = removeWhiteSpaces(cell['_nativeValue']);
          }

          acc[removeAgg(col.field)] = col.parser(cell);
          return acc;
        };

        // Save the parseColumnData function to the context so that we can use it during the streaming
        gridOptions.context = {
          ...gridOptions.context,
          parseColumnData,
        };

        let rowData = !rows
          ? []
          : rows.map((row, rowIndex) =>
              row.reduce(parseColumnData, { index: rowIndex + 1, ...additionalColumnsRowMap })
            );

        gridOptions.rowData = rowData;
        console.timeEnd('createRowData');
      }

      // if currentGridState.pivotMode is not the same as the gridOptions.pivotMode, then set it
      if (currentGridState.pivotMode !== gridOptions.pivotMode) {
        gridOptions.pivotMode = currentGridState.pivotMode;
      }

      showAGGrid(gridOptions, currentGridState, {
        updateColumnDefs,
        theme,
        showCompactMode,
        useTableauFont,
        useAccessibilityMode,
        fetchMethod,
        groupColumnsPanel,
      });

      updateColumnDefs = false;
    } catch (error: any) {
      console.error(error);
      showError(error);
    }
    console.groupEnd('update');
    console.timeEnd('update');
  }

  // create custom external event to toggle columns
  let columnDefs: Array<ColDef | ColGroupDef> | undefined;
  //TODO: Event doesn't seem to have .detail. Using any as a alternative.
  document.addEventListener('toggle-columns', (event: any) => {
    if (!columnDefs) {
      columnDefs = gridApi?.getColumnDefs();
    }
    for (let i = 0; i < event.detail.length; i++) {
      // @ts-ignore
      newToggle(columnDefs, event.detail[i]);
    }
    if (columnDefs) {
      gridApi?.setGridOption('columnDefs', columnDefs);
    }
  });

  function newToggle(columnDefs: Array<ColDef | ColGroupDef>, key = null) {
    for (let j = 0; j < columnDefs.length; j++) {
      const curentColDef = columnDefs[j];
      // no children just a column
      if (!('children' in curentColDef) && curentColDef.headerName === key && 'hide' in curentColDef) {
        curentColDef.hide = !curentColDef.hide;
      }
      // hide a group
      else if ('children' in curentColDef && curentColDef.headerName === key) {
        newToggle(curentColDef.children);
      }
      // the toggle is a subgroup?
      else if ('children' in curentColDef && curentColDef.children && key) {
        newToggle(curentColDef.children, key);
      }
      // a subgroup with children
      else if ('children' in curentColDef && curentColDef.children && key === null) {
        newToggle(curentColDef.children);
      }
      // hide all children
      else if ('hide' in curentColDef && key === null) {
        curentColDef.hide = !curentColDef.hide;
      }
    }
  }

  const isVizExtension = !!extension.worksheetContent;
  /**
   * Attach tableau event listeners to update the data
   * @todo: do we wanna update on parameter changes as well?
   */
  /**
   * Attach tableau event listeners to update the data
   */
  if (settings.enableEventFilterChanged) {
    let unregisterFilter = datasheet.addEventListener(
      TableauEventType.FilterChanged,
      immediateThenDebounce(update, 500)
    );
    unregisterFns.push(unregisterFilter);

    // add secondary worksheets filter event listeners
    if (
      !isVizExtension &&
      settings.secondaryEventFilterWorksheets &&
      settings.secondaryEventFilterWorksheets.length > 0
    ) {
      let secondarySheet;
      settings.secondaryEventFilterWorksheets.forEach((pm) => {
        secondarySheet = window.tableau.extensions.dashboardContent?.dashboard.worksheets.find(
          (sheet) => sheet.name === pm.value
        );
        if (secondarySheet) {
          unregisterFilter = secondarySheet.addEventListener(
            TableauEventType.FilterChanged,
            immediateThenDebounce(update, 500)
          );
          unregisterFns.push(unregisterFilter);
        }
      });
    }
  }
  if (settings.enableEventMarkSelectionChanged && !isVizExtension) {
    let unregisterMarks = datasheet.addEventListener(
      TableauEventType.MarkSelectionChanged,
      immediateThenDebounce(update, 500)
    );
    unregisterFns.push(unregisterMarks);
  }

  if (settings.enableEventParameterChanged) {
    let stateParam: Parameter;
    getParametersAsync()?.then(function (parameters) {
      parameters.forEach(function (parameter) {
        if (settings.excludeEventParameters) {
          for (let i = 0; i < settings.excludeEventParameters.length; i++) {
            if (settings.excludeEventParameters[i].value === parameter.name) {
              return;
            }
          }
        }
        if (parameter.name === stateParameter) {
          stateParam = parameter;
          return;
        }

        // Multi Layout Switching with Parameter
        let fn;
        if (
          settings.enableMultiLayoutsWithParameter &&
          settings.multiLayoutsParameters &&
          parameter.name === settings.multiLayoutsParameters.value
        ) {
          fn = parameter.addEventListener(TableauEventType.ParameterChanged, function () {
            findParameterAsync(settings.multiLayoutsParameters.value)?.then(function (pm) {
              if (stateParam) {
                stateParam.changeValueAsync(pm.currentValue.value);
              }
              initializeApp('multi-layout-switching');
            });
          });
        } else if (
          settings.useParameterForDatasheet &&
          settings.datasheetParameter &&
          settings.datasheetParameter === parameter.name
        ) {
          fn = parameter.addEventListener(TableauEventType.ParameterChanged, function () {
            initializeApp('datasheet-switching');
          });
        } else {
          fn = parameter.addEventListener(TableauEventType.ParameterChanged, function (event) {
            let index = ignoreParameterChanges.indexOf(parameter.name);
            if (index !== -1) {
              ignoreParameterChanges.splice(index, 1);
              return;
            }

            update(event);
          });
        }
        unregisterFns.push(fn);
      });
    });
  }

  console.timeEnd('setup');

  update();
}

function applyPreviousStateToColumnDefinitions(columnDefinitions: Array<ColDef>, previousState: Array<ColumnState>) {
  const previousStateMap = new Map(previousState.map((col) => [col.colId, col]));

  // Update the columnDefinitions with values from the previous state
  columnDefinitions.forEach((colDef) => {
    const prevStateCol = colDef.field ? previousStateMap.get(colDef.field) : undefined;
    if (prevStateCol) {
      // Iterate over properties in the previous state
      Object.keys(prevStateCol).forEach((key) => {
        //@ts-ignore
        if (prevStateCol[key] !== undefined && colDef[key] !== prevStateCol[key]) {
          //@ts-ignore
          colDef[key] = prevStateCol[key]; // Update with the previous state value
        }
      });
    }
  });

  return columnDefinitions;
}

function showNoDataOverlay(settings: Settings) {
  if ('showCustomNotification' in settings) {
    settings.showCustomNotification
      ? showAlert(
          settings.customNotificationHeader || settings.customNotificationHeader === ''
            ? settings.customNotificationHeader
            : 'No data available',
          settings.customNotificationText || settings.customNotificationText === ''
            ? settings.customNotificationText
            : 'This can be caused by filters'
        )
      : showAlert('No data available', 'This can be caused by filters');
  } else {
    showAlert('No data available', 'This can be caused by filters');
  }
}
