import FormulasPanel from '@/components/formula/FormulasPanel';
import React from 'react';
import { createRoot } from 'react-dom/client';

export default class FormulasToolPanel {
  eGui: HTMLDivElement | null = null;

  init() {
    this.eGui = document.createElement('div');
    this.eGui.style.height = '100%';
    this.eGui.style.width = '100%';

    const App: React.FC = () => {
      return <FormulasPanel />;
    };

    setTimeout(() => {
      const root = createRoot(this.eGui!);
      root.render(<App />);
    }, 1000);
  }

  getGui() {
    return this.eGui;
  }

  refresh() {}
}
