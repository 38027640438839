import { GridApi } from 'ag-grid-community';
import { getBase64ImageFromURL } from '@/utils/functional';

export default async function getDocDefinition(agGridApi: GridApi) {
  const PDF_HEADER_COLOR = '#f8f8f8';
  const PDF_INNER_BORDER_COLOR = '#dde2eb';
  const PDF_OUTER_BORDER_COLOR = '#babfc7';
  const PDF_ODD_BKG_COLOR = '#fcfcfc';
  const PDF_EVEN_BKG_COLOR = '#ffffff';
  const PDF_HEADER_HEIGHT = 25;
  const PDF_ROW_HEIGHT = 15;
  const PDF_PAGE_ORITENTATION = 'landscape';
  const PDF_WITH_CELL_FORMATTING = true;
  const PDF_SELECTED_ROWS_ONLY = false;
  const PDF_WITH_HEADER_IMAGE = false;
  const PDF_WITH_FOOTER_PAGE_COUNT = true;

  const columnGroupsToExport = getColumnGroupsToExport();

  const columnsToExport = getColumnsToExport();

  const widths = getExportedColumnsWidths(columnsToExport);

  const rowsToExport = await getRowsToExport(columnsToExport);

  const body = columnGroupsToExport
    ? [columnGroupsToExport, columnsToExport, ...rowsToExport]
    : [columnsToExport, ...rowsToExport];

  const headerRows = columnGroupsToExport ? 2 : 1;

  const header = PDF_WITH_HEADER_IMAGE
    ? {
        image: 'ag-grid-logo',
        width: 150,
        alignment: 'center',
        margin: [0, 10, 0, 10],
      }
    : null;

  const footer = PDF_WITH_FOOTER_PAGE_COUNT
    ? function (currentPage, pageCount) {
        return {
          text: currentPage.toString() + ' of ' + pageCount,
          margin: [20],
        };
      }
    : undefined; //ToDO

  const pageMargins = [10, PDF_WITH_HEADER_IMAGE ? 70 : 20, 10, PDF_WITH_FOOTER_PAGE_COUNT ? 40 : 10];

  const heights = (rowIndex) => (rowIndex < headerRows ? PDF_HEADER_HEIGHT : PDF_ROW_HEIGHT);

  const fillColor = (rowIndex, node, columnIndex) => {
    if (rowIndex < node.table.headerRows) {
      return PDF_HEADER_COLOR;
    }
    return rowIndex % 2 === 0 ? PDF_ODD_BKG_COLOR : PDF_EVEN_BKG_COLOR;
  };

  const hLineWidth = (i, node) => (i === 0 || i === node.table.body.length ? 1 : 1);

  const vLineWidth = (i, node) => (i === 0 || i === node.table.widths.length ? 1 : 0);

  const hLineColor = (i, node) =>
    i === 0 || i === node.table.body.length ? PDF_OUTER_BORDER_COLOR : PDF_INNER_BORDER_COLOR;

  const vLineColor = (i, node) =>
    i === 0 || i === node.table.widths.length ? PDF_OUTER_BORDER_COLOR : PDF_INNER_BORDER_COLOR;

  const docDefintiion = {
    pageOrientation: PDF_PAGE_ORITENTATION,
    header,
    footer,
    content: [
      {
        style: 'SuperTable',
        table: {
          headerRows,
          widths,
          body,
          heights,
        },
        layout: {
          fillColor,
          hLineWidth,
          vLineWidth,
          hLineColor,
          vLineColor,
        },
      },
    ],
    defaultStyle: {
      fontSize: 5,
    },
    styles: {
      myTable: {
        margin: [0, 0, 0, 0],
      },
      tableHeader: {
        bold: true,
        margin: [0, PDF_HEADER_HEIGHT / 3, 0, 0],
      },
      tableCell: {
        margin: [0, 0, 0, 0],
      },
    },
    pageMargins,
  };

  function getColumnGroupsToExport() {
    let displayedColumnGroups = agGridApi.getAllDisplayedColumnGroups();

    let isColumnGrouping = displayedColumnGroups?.some((col) => col.hasOwnProperty('children'));

    if (!isColumnGrouping) {
      return null;
    }

    let columnGroupsToExport = [];

    displayedColumnGroups?.forEach((colGroup) => {
      let isColSpanning = colGroup.displayedChildren.length >= 1;
      let numberOfEmptyHeaderCellsToAdd = 0;

      if (isColSpanning) {
        let headerCell = createHeaderCell(colGroup);
        columnGroupsToExport.push(headerCell);
        // subtract 1 as the column group counts as a header
        numberOfEmptyHeaderCellsToAdd--;
      }

      // add an empty header cell now for every column being spanned
      colGroup.displayedChildren.forEach((childCol) => {
        //columnGroupShow
        let pdfExportOptions = getPdfExportOptions(childCol.getColId());
        if (!pdfExportOptions || !pdfExportOptions.skipColumn) {
          numberOfEmptyHeaderCellsToAdd++;
        }
      });

      for (let i = 0; i < numberOfEmptyHeaderCellsToAdd; i++) {
        columnGroupsToExport.push({});
      }
    });

    return columnGroupsToExport;
  }

  function getColumnsToExport() {
    let columnsToExport = [];

    agGridApi.getAllDisplayedColumns().forEach((col) => {
      let pdfExportOptions = getPdfExportOptions(col.getColId());
      if (pdfExportOptions && pdfExportOptions.skipColumn) {
        return;
      }
      let headerCell = createHeaderCell(col);
      columnsToExport.push(headerCell);
    });

    return columnsToExport;
  }

  async function getRowsToExport(columnsToExport) {
    let rowsDone = 0;
    let p = new Promise((resolve) => {
      let rowsToExport = [];
      agGridApi.forEachNodeAfterFilterAndSort(async (node) => {
        let isLastRow = false;
        const findLastRow = (node) => {
          let parent = node;
          if (node.level > 0) {
            while ('parent' in parent && parent.level > 0) {
              if (!parent.lastChild) {
                break;
              }
              parent = parent.parent;
              isLastRow = parent.lastChild;
            }
          } else {
            isLastRow = node.lastChild;
          }
        };

        const indents = (level) => {
          let indents = '';
          for (let i = 0; i < level; i++) {
            indents += '-';
          }
          return indents;
        };

        if (PDF_SELECTED_ROWS_ONLY && !node.isSelected()) {
          return;
        }

        if (!('allLeafChildren' in node) && node.lastChild && !agGridApi.isPivotMode()) {
          findLastRow(node);
        } else if (node.lastChild && node.rowIndex !== null) {
          findLastRow(node);
        }

        // remove in pivot mode the underlying data
        if (node.rowIndex === null && agGridApi.isPivotMode()) {
          // return;
        } else {
          let rowToExport = await columnsToExport.map(async ({ colId }, index) => {
            let cellValue =
              node.key !== null && index === 0
                ? indents(node.level) + agGridApi.getCellValue({ rowNode: node, colKey: colId })
                : agGridApi.getCellValue({ rowNode: node, colKey: colId });
            let tableCell = await createTableCell(cellValue, colId);
            return tableCell;
          });
          rowsToExport.push(await rowToExport);

          if (isLastRow) {
            const promise4All = Promise.all(
              rowsToExport.map(async (row) => {
                return await Promise.all(row).then((data) => {
                  rowsDone++;
                  document.getElementById('ExportPercentageComplete').innerHTML =
                    'Processed: <strong>' +
                    Math.round((rowsDone / agGridApi.getDisplayedRowCount()) * 100) +
                    '%</strong>';
                  return data;
                });
              })
            );
            promise4All.then((data) => {
              resolve(data);
            });
          }
        }
      });
    });

    let out = await p;

    return out;
  }

  function getExportedColumnsWidths(columnsToExport) {
    return columnsToExport.map(() => 'auto');
    //100 / columnsToExport.length + '%'
  }

  function createHeaderCell(col) {
    let headerCell = {};
    let isColGroup = col.hasOwnProperty('children');

    if (isColGroup) {
      headerCell.text = col.providedColumnGroup.colGroupDef ? col.providedColumnGroup.colGroupDef.headerName : '';
      headerCell.colSpan = col.displayedChildren.length;
      headerCell.colId = col.groupId;
    } else {
      let headerName = col.colDef.headerName;

      if (col.sort) {
        headerName += ` (${col.sort})`;
      }
      if (col.filterActive) {
        headerName += ` [FILTERING]`;
      }

      headerCell.text = headerName;
      headerCell.colId = col.getColId();
    }

    headerCell['style'] = 'tableHeader';

    return headerCell;
  }

  async function createTableCell(cellValue, colId) {
    const tableCell = {
      text: cellValue !== undefined ? cellValue : '',
      style: 'tableCell',
    };

    const pdfExportOptions = getPdfExportOptions(colId);

    if (pdfExportOptions) {
      const { styles, createURL, valueFormatter, isImage } = pdfExportOptions;

      if (PDF_WITH_CELL_FORMATTING && styles) {
        Object.entries(styles).forEach(([key, value]) => (tableCell[key] = value));
      }

      if (isImage && import.meta.env.VITE_PRODUCT === 'e') {
        try {
          return {
            image: await getBase64ImageFromURL(cellValue),
            width: 20,
          };
        } catch (error) {
          return tableCell;
        }
      }
      // TODO LINKS
      // if (PDF_WITH_COLUMNS_AS_LINKS && createURL) {
      //   tableCell['link'] = createURL(cellValue);
      //   tableCell['color'] = 'blue';
      //   tableCell['decoration'] = 'underline';
      // }

      if (valueFormatter && typeof valueFormatter === 'function') {
        tableCell['text'] = valueFormatter({ value: cellValue, type: 'pdf' });
      }
    }

    return tableCell;
  }

  function getPdfExportOptions(colId) {
    let col = agGridApi.getColumn(colId);

    if (!col) {
      return false;
    }
    return col.getColDef().pdfExportOptions ? col.getColDef().pdfExportOptions : false;
  }

  return docDefintiion;
}
