import { CellClassParams } from 'ag-grid-community';
import { Column } from '@tableau/extensions-api-types';
import { ColumnConfig } from '../../types/settings';
import { Settings } from '../../settings';
import { isCalculation, removeSpaces, isValueEmpty } from './utils';
import { getValueColumnValue } from '.';
import { Calculation } from '@/types/mapping';

export function makeCellClass(
  config: ColumnConfig,
  column: Calculation | Column,
  settings: Settings,
  params: CellClassParams<any, any>
) {
  const classes = ['exportStyle'];
  // column font and background coloring
  if (config?.color?.backgroundColor || config?.color?.fontColor) {
    let className = isCalculation(column)
      ? removeSpaces(column.index + '_ColumnStyle')
      : removeSpaces(column.fieldName + '_ColumnStyle');
    classes.push(className);
  }

  // row background coloring
  // WIP does not support calculations as row background unfortunately.
  const rowColorColumn = settings.rowColorColumn;
  let colorCol;
  if (rowColorColumn) {
    colorCol = settings.columnConfig[rowColorColumn];
  }
  if (
    rowColorColumn &&
    colorCol &&
    params.data &&
    params.data[rowColorColumn] &&
    settings.columnConfig[rowColorColumn] &&
    'color' in colorCol &&
    'type' in colorCol.color &&
    colorCol.color.type === 'continuous'
  ) {
    const valueColumn = colorCol.color.valueColumn || settings.rowColorColumn;
    if (!valueColumn) return classes;

    const rawVale = params.api.getCellValue({ rowNode: params.node, colKey: valueColumn });
    let val = rawVale && Number(rawVale);

    if (isValueEmpty(val)) return classes;

    let upperBound = Number(colorCol.color.upperBound);
    let lowerBound = Number(colorCol.color.lowerBound);
    if (colorCol.color.upperBound === undefined || colorCol.color.upperBound === '') {
      val = val ? (val > lowerBound ? 1 : 0) : 0;
      val = val.toFixed(2);
    } else if (colorCol.color.lowerBound === undefined || colorCol.color.lowerBound === '') {
      val = val ? (val > upperBound ? 1 : 0) : 0;
      val = val.toFixed(2);
    } else {
      let range: Array<number> = [];
      let step = (upperBound - lowerBound) / 10;
      step = step === 0 ? 1 : step;
      let size = upperBound - lowerBound;
      for (let j = 0; j <= size; j = j + step) {
        if (lowerBound && j) {
          range.push(parseFloat((lowerBound + j).toFixed(2)));
        }
      }
      range.shift();
      range.pop();

      const getNearestValue = (arr: number[], val: number) => {
        return arr.reduce((p: number, n: number) => (Math.abs(p) > Math.abs(n - val) ? n - val : p), Infinity) + val;
      };
      const nearestValue = getNearestValue(range, val);
      val =
        val !== null && val !== undefined
          ? val > upperBound
            ? 'upper'
            : val < lowerBound
              ? 'lower'
              : typeof nearestValue === 'number' && !Number.isNaN(nearestValue)
                ? Math.round(nearestValue * 100) / 100
                : val
          : val;
    }

    const className = removeSpaces(settings.rowColorColumn + '_' + String(val));
    classes.push(className);
  }

  if (settings?.rowColorColumn && params.data && params.data[settings.rowColorColumn]) {
    const rowColumn = settings.columnConfig[settings.rowColorColumn];
    const rowColumnValueColumn = rowColumn?.color?.valueColumn || settings.rowColorColumn;
    const className = removeSpaces(settings.rowColorColumn + '_' + String(params.data[rowColumnValueColumn]));
    classes.push(className);
  }

  // conditional coloring
  const currentColumnName = isCalculation(column) ? column?.index : column?.fieldName;
  const valueColumnName = config?.color?.valueColumn || currentColumnName;

  if (!config?.color) return classes;

  const isValueColumnContinuous = config?.color?.type === 'continuous';
  let val: number | string = getValueColumnValue(valueColumnName.toString(), isValueColumnContinuous, params);
  if (!isValueColumnContinuous) {
    if (isValueEmpty(val) && isValueEmpty(params.value)) return classes;
    if (params.value) {
      if (params.node.group) {
        let rowIndex = params.node.rowIndex;
        if (!rowIndex) return classes;

        let requiredRow = params.api.getDisplayedRowAtIndex(rowIndex);
        if (!requiredRow) return classes;

        let found = false;
        while (!found && requiredRow) {
          if (requiredRow?.field && requiredRow?.field === valueColumnName && requiredRow.key) {
            found = true;
            val = requiredRow.key;
          } else {
            requiredRow = requiredRow.parent ?? undefined;
          }
        }
      }
    }

    const columnName = Number.isInteger(column?.index) ? column?.fieldName : column?.index;
    const className = removeSpaces(columnName + '_' + String(val));
    classes.push(className);
  } else {
    if (typeof val === 'string') val = Number(val);
    if (isValueEmpty(val)) return classes;
    if (config.color.type !== 'continuous') return classes;

    let upperBound = Number(config.color.upperBound);
    let lowerBound = Number(config.color.lowerBound);
    if (config.color.upperBound === undefined || config.color.upperBound === '') {
      val = val ? (val >= lowerBound ? 1 : 0) : 0;
      val = Math.round(val * 100) / 100;
    } else if (config.color.lowerBound === undefined || config.color.lowerBound === '') {
      val = val ? (val > upperBound ? 1 : 0) : 0;
      val = Math.round(val * 100) / 100;
    } else {
      let range: Array<number> = [];
      let step = (upperBound - lowerBound) / 10;
      step = step === 0 ? 1 : step;
      let size = upperBound - lowerBound;
      for (let j = 0; j <= size; j = j + step) {
        range.push(parseFloat((lowerBound + j).toFixed(2)));
      }
      range.shift();
      range.pop();
      const getNearestValue = (arr: number[], val: number) => {
        return arr.reduce((p: number, n: number) => (Math.abs(p) > Math.abs(n - val) ? n - val : p), Infinity) + val;
      };
      const nearestValue = getNearestValue(range, val);
      val =
        val !== null && val !== undefined
          ? val > upperBound
            ? 'upper'
            : val < lowerBound
              ? 'lower'
              : typeof nearestValue === 'number' && !Number.isNaN(nearestValue)
                ? Math.round(nearestValue * 100) / 100
                : val
          : val;
    }

    const columnName = Number.isInteger(column.index) ? column.fieldName : column.index;
    const className = removeSpaces(columnName + '_' + String(val));
    classes.push(className);
  }
  return classes;
}
