type Handler = (data: any) => void;

class EventManager {
  handlers = new Map<string, Handler[]>();

  addEventListener(event: string, handler: Handler) {
    let handlers = this.handlers.get(event) || [];
    this.handlers.set(event, handlers.concat([handler]));
    return () => this.removeEventListener(event, handler);
  }

  removeEventListener(event: string, handler: Handler) {
    let handlers = this.handlers.get(event) || [];
    this.handlers.set(
      event,
      handlers.filter((h) => h !== handler)
    );
  }

  triggerEvent(event: string, eventData: any = undefined) {
    let handlers = this.handlers.get(event) || [];
    handlers.forEach((handler) => handler(eventData));
  }
}

export const EventBus = new EventManager();
