export function rgbToHex(rgb: string[]): string {
  return (
    '#' +
    rgb
      .map((x) => {
        const hex = parseInt(x).toString(16);
        return hex.length === 1 ? '0' + hex : hex;
      })
      .join('')
  );
}
