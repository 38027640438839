import { Label } from '@/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { FormulaType } from '@/types/settings';
import { cn } from '@/utils/cn';
import { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { LuAlignCenterVertical } from 'react-icons/lu';

const AlignmentOptions = ({ formula, setFormula }: { formula: FormulaType; setFormula: (f: FormulaType) => void }) => {
  const [aggregationOpen, setAggregationOpen] = useState(false);

  const { headerAlignment, alignment } = formula;

  function updateProperty(key: keyof FormulaType, value: string) {
    setFormula((f: FormulaType) => ({ ...f, [key]: value }));
  }

  return (
    <div className="rounded border">
      <div
        className="flex items-center justify-between gap-2 rounded p-2"
        onClick={() => setAggregationOpen((o) => !o)}>
        <div>
          <LuAlignCenterVertical size={16} />
        </div>
        <h3 className="flex-1 text-left text-lg font-bold">Alignment Options</h3>
        <div className={cn('box-border grid place-items-center p-1', aggregationOpen ? 'rotate-180' : 'rotate-0')}>
          <FaChevronDown size={13} />
        </div>
      </div>
      <div className={cn('flex-col justify-between gap-1.5 p-2', aggregationOpen ? 'flex' : 'hidden')}>
        <div className="flex items-start justify-between gap-1">
          <div className="flex flex-1 flex-col items-start">
            <Label className="text-lg">Column alignment</Label>
            <Select
              onValueChange={(value) => updateProperty('alignment', value)}
              value={alignment}>
              <SelectTrigger>
                <SelectValue placeholder="Select format type" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="-1">Auto</SelectItem>
                <SelectItem value="left">Left</SelectItem>
                <SelectItem value="center">Center</SelectItem>
                <SelectItem value="right">Right</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="flex flex-1 flex-col items-start">
            <Label className="text-lg">Header alignment</Label>
            <Select
              onValueChange={(value) => updateProperty('headerAlignment', value)}
              value={headerAlignment}>
              <SelectTrigger>
                <SelectValue placeholder="Select format type" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="-1">Auto</SelectItem>
                <SelectItem value="left">Left</SelectItem>
                <SelectItem value="center">Center</SelectItem>
                <SelectItem value="right">Right</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlignmentOptions;
