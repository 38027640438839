import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community';

interface ImageCellRendererParams extends ICellRendererParams {
  url: (data: any) => string;
  format: any;
}

export default class ImageCellRenderer implements ICellRendererComp {
  eGui!: HTMLElement;

  init({ value, data, format, node, url }: ImageCellRendererParams): void {
    this.eGui = document.createElement('div');

    if (!value) return;

    const maxWidth = format.imageWidth ? format.imageWidth + 'px' : '100%';
    const minHeight = format.imageHeight ? format.imageHeight + 'px' : 'auto';

    const imageDiv = document.createElement('div');
    imageDiv.style.minHeight = minHeight;
    imageDiv.innerHTML = `
    <img
        id="${value}"
        border="0"
        width="100%" height="auto"
        style="max-width: ${maxWidth} !important; padding: 0px;"
        src="${value}"
    />
    `;

    // we need data for dynamic URLS, there is no data in grouping mode
    // if not grouping use the data (row) else pick the first row of the group rows
    let newData = data ? data : node.allLeafChildren.length > 0 ? node.allLeafChildren[0].data : null;
    if (url && newData) {
      const link = document.createElement('a');
      link.target = '_blank';
      link.rel = 'noopener noreferrer';

      link.href = url(newData);
      link.innerHTML = imageDiv.innerHTML;
      this.eGui = link;
      return;
    }

    this.eGui = imageDiv;
  }

  getGui(): HTMLElement {
    return this.eGui;
  }

  refresh(): boolean {
    return true;
  }
}
