import { ColorPicker } from '@/components/ui/colorPicker';
import { FormulaColorConfig, FormulaType } from '@/types/settings';
import { cn } from '@/utils/cn';
import { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { MdFormatColorFill } from 'react-icons/md';

const ColorOptions = ({ formula, setFormula }: { formula: FormulaType; setFormula: (f: FormulaType) => void }) => {
  const [colorOptionsOpen, setColorOptionsOpen] = useState(false);

  function updateFormulaColor(key: keyof FormulaColorConfig, value: string | undefined) {
    setFormula((f: FormulaType) => ({ ...f, color: { ...f.color, [key]: value } }));
  }

  type ColorOption = {
    title: string;
    key: 'backgroundColor' | 'fontColor' | 'headerBackgroundColor' | 'headerFontColor';
  };

  const colors: ColorOption[] = [
    {
      title: 'Column background color',
      key: 'backgroundColor',
    },
    {
      title: 'Column font color',
      key: 'fontColor',
    },
    {
      title: 'Header Background Color',
      key: 'headerBackgroundColor',
    },
    {
      title: 'Header Font Color',
      key: 'headerFontColor',
    },
  ];

  return (
    <div className="rounded border">
      <div
        className="flex items-center justify-between gap-2 p-2"
        onClick={() => setColorOptionsOpen((o) => !o)}>
        <div>
          <MdFormatColorFill size={16} />
        </div>
        <h3 className="flex-1 text-left text-lg font-bold">Color Options</h3>
        <div className={cn('box-border grid place-items-center p-1', colorOptionsOpen ? 'rotate-180' : 'rotate-0')}>
          <FaChevronDown size={13} />
        </div>
      </div>
      <div className={cn('flex-col justify-between gap-1.5 p-2', colorOptionsOpen ? 'flex' : 'hidden')}>
        {colors.map((object: ColorOption) => (
          <ColorPicker
            key={object.key}
            id={object.key}
            title={object.title}
            value={formula.color?.[object.key]}
            defaultValue="#ffffff"
            onColorChange={({ target }) => updateFormulaColor(object.key, target.value)}
            onColorClear={() => updateFormulaColor(object.key, undefined)}
          />
        ))}
      </div>
    </div>
  );
};

export default ColorOptions;
