import { ColumnConfig } from '@/types/settings';
import { Column } from '@tableau/extensions-api-types';
import { isDate } from 'date-fns';
import { Calculation } from '@/types/mapping';

export function getPivotHeaderSorter(config: ColumnConfig, column: Column | Calculation) {
  const isDesc = config?.pivotColumnDescending;
  if (config?.useCustomSort) {
    const custom = createCustomComparator(config, column.fieldName, true);
    if (custom) return custom;
    else
      console.warn(
        `Custom sort order for column ${column.fieldName} is not valid. Falling back to default sort order.`
      );
  }

  const ascMultiplier = isDesc ? -1 : 1;
  return (a, b) => {
    const result = (a > b ? 1 : a < b ? -1 : 0) * ascMultiplier;
    if (!isDate(column)) return result;

    // Date.parse is not the right function since the formatted date can be of any type (YYYY-MM, YYYY-WW).
    // if it is `WW then values of a, b will be between 1 and 53.
    // However 13-31 will return NaN in Date.parse (weird)
    // Date.parse('2024-12') will return Dec 1 2024 but the 12 could be WW as well.
    // Date.parse is not reliable and we need to use the formatted value.
    const format = config.format?.date || 'YYYY-MM-DD';
    let dateA = Date.parse(a);
    let dateB = Date.parse(b);
    if (format === 'WW') {
      dateA = Number(a);
      dateB = Number(b);
    }

    if (isNaN(dateA) || isNaN(dateB)) {
      if (isNaN(a) || isNaN(b)) {
        return result;
      }
      return (Number(a) - Number(b)) * ascMultiplier;
    }
    return (dateA - dateB) * ascMultiplier;
  };
}

export function createCustomComparator(
  settings: ColumnConfig,
  columnName: any,
  isPivot = false
): undefined | ((...args: any[]) => number) {
  const order = settings?.customSortOrder;

  if (!order) {
    return undefined;
  }

  return (a, b, nodeA, nodeB) => {
    const isPivotDesc = isPivot && settings.pivotColumnDescending;
    const isColumnUsedAsRowGrouping = nodeA?.field === columnName;

    if ((nodeA?.group || nodeB?.group) && !isColumnUsedAsRowGrouping) {
      const aggFuncType = typeof a === 'object' ? 'countOrCountD' : 'firstOrLast';

      if (aggFuncType === 'countOrCountD') {
        const valueA = a?.value;
        const valueB = b?.value;
        return valueA - valueB;
      } else {
        // firstOrLast
        // not using isInverted here because ag-grid (somehow) inverts the values
        return order[a] - order[b];
      }
    }
    return isPivotDesc ? order[b] - order[a] : order[a] - order[b];
  };
}
