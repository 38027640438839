import { gridOptions } from '@/grid/options';
import {
  Column,
  GridApi,
  ICellRendererParams,
  IRowNode,
  GridOptions,
  RowHeightCallbackParams,
  ProcessCellForExportParams,
  RowSpanParams,
  ValueFormatterParams,
} from 'ag-grid-community';
import { LicenseManager } from 'ag-grid-enterprise';
import formatDate from 'date-fns/format';
import { getSettings, Settings } from '../settings';
import isValidDate from 'date-fns/is_valid';
import { gridApi } from '@/grid';

/**
 * @note this is not an accurate typing of the params
 */
export function isFirstColumn(params: RowSpanParams) {
  if (!params.api) return false; // make ts happy
  var displayedColumns = params.api.getAllDisplayedColumns();
  var thisIsFirstColumn = displayedColumns[0] === params.column;
  return thisIsFirstColumn;
}

export function formatWithValueFormatter(params: ProcessCellForExportParams | ValueFormatterParams) {
  let formatter = params.column.getColDef()?.valueFormatter;
  if (formatter) {
    // @ts-ignore: this is a hack to remove (thousand) seperators
    if (formatter.exportFormatter) formatter = formatter.exportFormatter;
    // @ts-ignore: our formatters only use params.value and dont care about
    // data and colDef which are not present on ProcessCellForExportParams
    return formatter(params);
  } else if (params.node?.group && params.node.field) {
    formatter = params.api.getColumnDef(params.node.field)?.valueFormatter;
    if (formatter && typeof formatter === 'function') {
      return formatter(params as ValueFormatterParams);
    }
  }
  return params.value;
}

export function expandAllButLastNGroups(api: GridApi, n: number) {
  const groupCount = api.getRowGroupColumns().length;
  // we are avoiding the public api for setting the expansion status of a RowNode
  // since we might update a lot of rows and only want to render this update once
  api.forEachNode((node) => {
    node.expanded = node.level < groupCount - n;
  });

  // tell ag-grid to refresh/update the table
  api.onGroupExpandedOrCollapsed();
}

function isDate(date: string | number | Date): boolean {
  return new Date(date) ? !isNaN(new Date(date).getTime()) : false;
}

function getDateFormat(key: string | number | Date) {
  let hour = formatDate(key, 'HH');
  let minute = formatDate(key, 'mm');
  if (hour !== '00' && minute !== 'mm') {
    key = formatDate(key, 'YYYY-MM-DD HH:mm');
  } else {
    key = formatDate(key, 'YYYY-MM-DD');
  }
  return key;
}

function addKeyFromNode(node: IRowNode, keys: string[]) {
  const total = (gridOptions.localeText?.total || 'Total') + ' ';
  const subtotal = (gridOptions.localeText?.subtotal || 'Sub total') + ' ';
  if (node.key !== null && node.key !== undefined) {
    let key = node.key;
    if (isDate(key)) {
      key = getDateFormat(key).toString();
    }
    if (!node.footer) {
      keys.push(key);
    } else {
      if (node.level === 0) {
        keys.push(total + key);
      } else {
        keys.push(subtotal + key);
      }
    }
  }
}

function createValueForGroup(node: IRowNode) {
  if (node.level === -1) {
    return 'Total';
  }

  const keys: string[] = [];
  addKeyFromNode(node, keys);
  while (node.parent) {
    node = node.parent;
    addKeyFromNode(node, keys);
  }
  return keys.filter(Boolean).reverse().join(' -> ');
}

export function prepareGroupedColumnValues(params: ProcessCellForExportParams) {
  const settings = getSettings();
  // single group column
  if (settings && !settings.showGroupMultiColumn) {
    let showRowGroup = params.column.getColDef().showRowGroup;
    if (showRowGroup && (params.value !== undefined || params.value !== null)) {
      // group cell
      params.value = params.node ? createValueForGroup(params.node) : undefined;
    }
    return params;
  }

  // if row grouped values are already present
  if (settings && settings.showOpenedGroup) {
    return params;
  }

  if (!params.value) {
    if (params.node?.data) {
      if (params.node.level !== -1) {
        const showRowGroup = params.column.getColDef().showRowGroup;
        if (typeof showRowGroup === 'string') {
          const value = params.node.data[showRowGroup];
          params.value = value;
        }
      } else {
        params.value = 'Total';
      }
    } else if (isGroupColumn(params.column) && params.node?.parent?.key) {
      params.value = params.node.parent.key;
    }
  }

  return params;
}

export function isGroupColumn(column: Column) {
  return Boolean(column.getColDef().showRowGroup);
}

/**
 * createValueGetter creates a function that will get the value of a field.
 * @note RowSpanParams -> ValueFormatterParams are not an accurate typing...
 * @returns {(params: RowSpanParams) => ValueFormatterParams}
 */
export function createValueGetter(field: string) {
  return function (params: RowSpanParams) {
    let value =
      params.api && params.node ? params.api.getCellValue({ rowNode: params.node, colKey: field }) : undefined;

    return { ...params, value };
  };
}

export function sortGroupsByCount(valueA: any, valueB: any, nodeA: IRowNode, nodeB: IRowNode) {
  if (nodeA && nodeB && nodeA.allChildrenCount && nodeB.allChildrenCount) {
    return nodeA.allChildrenCount - nodeB.allChildrenCount;
  }
  return 0;
}

export function sortGroupsByValueWithDates(valueA: any, valueB: any) {
  let a = valueA;
  let b = valueB;

  // Note: when grouping on dates the values are passed as strings
  // in order to sort correctly we need to parse the date strings and
  // compare the resulting date objects...
  let dateValueA = new Date(a);
  let dateValueB = new Date(b);
  if (isValidDate(dateValueA) && isValidDate(dateValueB)) {
    a = dateValueA;
    b = dateValueB;
  }

  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}

export function linkCellRenderer({
  url,
  data,
  value,
  valueFormatted,
  node,
}: ICellRendererParams & {
  url: (data: any) => string;
}) {
  let displayValue = valueFormatted ?? value;
  let link = document.createElement('a');
  link.target = '_blank';
  link.rel = 'noopener noreferrer';
  // we need data for dynamic URLS, there is no data in grouping mode
  // if not grouping use the data (row) else pick the first row of the group rows
  let newData = data
    ? data
    : node.allLeafChildren && node.allLeafChildren?.length > 0
      ? node?.allLeafChildren?.[0].data
      : null;
  let enableforGroup = node.group
    ? JSON.parse(window.tableau.extensions.settings.get('enableUrlActionsforGroupedValues') || 'true') || false
    : true;

  if (url && newData && enableforGroup) {
    const href = url(newData);
    if (!href || href === '%null%') return displayValue;
    link.href = href;
    link.textContent = displayValue;

    return link;
  }

  return displayValue;
}

/**
 * ensureLicenseKey fetches and registers the license key once.
 */
export async function ensureLicenseKey() {
  LicenseManager.setLicenseKey(
    `Using_this_AG_Grid_Enterprise_key_( AG-050706 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Infotopics AfT B.V )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Super Tables )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Super Tables )_need_to_be_licensed___( Super Tables )_has_been_granted_a_Deployment_License_Add-on_for_( Unlimited )_Production_Environments___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 22 January 2025 )____[v2]_MTczNzUwNDAwMDAwMA==26f9072e8eef650a7f97a42c304cfde5`
  );
}

let localeFile: LocaleFile = {};

type LocaleFile = {
  [key: string]: string;
};

export async function importLocale(): Promise<LocaleFile> {
  try {
    let response = await fetch('./static/localization.json');
    if (response.ok === false) {
      throw new Error('could not fetch localization file');
    }
    localeFile = (await response.json()) ?? {};
  } catch {
    console.log('localeFile not loaded');
  }

  return localeFile;
}

export function excelRowHeightGetter(
  gridOptions: GridOptions,
  settings: Settings,
  colId: string | undefined = undefined
): (params: RowHeightCallbackParams) => number {
  let previousRowHeight = 0;
  if (settings.autoRowHeight) {
    return ({ rowIndex }) => {
      // The first row is the header row, so we can use the default row height
      if (rowIndex === 0) return gridOptions?.rowHeight ?? 24;

      // The row index is 1-based because of the header row, so we need to subtract 1
      const rowNode = gridApi?.getRowNode((--rowIndex).toString());

      // When using auto row height, the row height is stored in the rowNode as __autoHeights
      // This is not documented, but it is used in the ag-grid source code
      // To get the maximum height of the row, we need to get the maximum value of the __autoHeights object
      // This is an object with the column id as key and the height as value
      // This property is only available when the row has been rendered that's why we need to check if it exists
      let maxHeight = 0;
      // @ts-expect-error - __autoHeights is private but we need it because it is the only way to get the row height defined by ag-grid
      if (!colId) maxHeight = Object.values(rowNode?.__autoHeights ?? []).reduce((a, b) => Math.max(a, b ?? 0), 0);
      else {
        // @ts-expect-error - __autoHeights is private but we need it because it is the only way to get the row height defined by ag-grid
        maxHeight = rowNode?.__autoHeights[colId] ?? previousRowHeight;
      }

      // We want to save the maxHeight so we can use it as the default row height for the next row if it isn't rendered yet
      if (maxHeight > 0) previousRowHeight = maxHeight;
      // Only use the rowheight of the node when we don't have a maxHeight from the __autoHeights object
      else if (rowNode?.rowHeight && rowNode?.rowHeight > previousRowHeight) previousRowHeight = rowNode?.rowHeight;

      return previousRowHeight;
    };
  } else {
    // Use the user defined row height otherwise use the default row height
    return () => {
      // Fallback to the default row height when the user didn't define a row height
      return settings.rowHeight ?? gridOptions.rowHeight ?? 24;
    };
  }
}
