// @ts-check

import { MenuItemDef } from 'ag-grid-community';

type MenuItemOptions = Pick<MenuItemDef, 'name' | 'action' | 'icon'>;

export default class MenuBuilder {
  menu: Array<MenuItemDef | string>;
  order: 'forward' | 'backward';

  constructor(defaultItems: Array<string> = [], order: 'forward' | 'backward' = 'backward') {
    this.menu = defaultItems;
    this.order = order;
  }
  _add(item: any) {
    // TODO: reverse operations in `getContextMenuItems` and use `build()` to append defaultItems
    switch (this.order) {
      case 'forward':
        return this.menu.push(item);

      case 'backward':
        return this.menu.unshift(item);
    }
  }

  addAction(
    name: string,
    action: () => void,
    options: Pick<MenuItemDef, Exclude<keyof MenuItemDef, 'name' | 'action'>> = {}
  ) {
    this._add({ ...options, name, action });
  }

  addActionWithIcon(name: string, icon: string, action?: { (): void }, options?: MenuItemOptions) {
    const span = document.createElement('span');
    span.setAttribute('class', 'ag-icon ag-icon-' + icon);
    span.setAttribute('unselectable', 'on');

    this._add({ ...options, name, action, icon: span });
  }

  addSubMenu(name: string, callback: (subMenu: MenuBuilder) => void) {
    let menu = new MenuBuilder();
    callback(menu);
    let subMenu = menu.build();
    this._add({ name, subMenu });
  }

  addSeparator() {
    this._add('separator');
  }

  build(defaultItems: Array<string> = []) {
    return this.menu.concat(defaultItems);
  }
}
