import { AgPromise, ICellRendererComp, ICellRendererParams } from 'ag-grid-community';
import { interpolateHsl } from 'd3-interpolate';
import { ContinuousColorConfig, DefaultColorConfig } from '../../types/settings';
import { debounce } from '@/utils/functional';
interface BarchartCellRendererParams extends ICellRendererParams {
  config: DefaultColorConfig & ContinuousColorConfig; //Makes more sense to extend instead by turning these into interfaces instead of types
}

type MinMax = {
  min: number;
  max: number;
};

export default class BarchartCellRenderer implements ICellRendererComp {
  static colAggData: Record<string, MinMax> = {};
  eGui!: HTMLElement;
  eBar!: HTMLElement;
  width: number = 0;
  value!: number;
  config!: DefaultColorConfig & ContinuousColorConfig;
  columnField?: string;

  updateBarchartBounds = (colAggData: Record<string, MinMax>) => {
    if (this.columnField && this.value !== undefined && this.value !== null && colAggData) {
      BarchartCellRenderer.colAggData = colAggData;

      const thisColAggData: MinMax = {
        min: (this.config.autoMin ? colAggData?.[this.columnField]?.min : Number(this.config.minValue)) ?? 0,
        max: (this.config.autoMax ? colAggData?.[this.columnField]?.max : Number(this.config.maxValue)) ?? 1,
      };

      // Make sure there are always min and max values
      if (isNaN(thisColAggData.min)) {
        thisColAggData.min = 0;
      } else if (isNaN(thisColAggData.max)) {
        thisColAggData.max = 1;
      }

      const valueToLerp = this.config.barchartUseAbsoluteValues ? Math.abs(this.value) : this.value;

      const width = thisColAggData ? unlerp(thisColAggData.min, thisColAggData.max, valueToLerp) * 100 : 0;
      this.width = width;
    }

    this.eBar.style.width = `${this.width}%`;
  };

  init(params: BarchartCellRendererParams): void | AgPromise<void> {
    this.eGui = document.createElement('div');

    const value = params.value?.value ?? params.value;
    const config = params.config;
    this.config = config;
    this.value = value;
    this.columnField = params.colDef?.field ?? '';

    const eBarWrapper = document.createElement('div');
    eBarWrapper.className = 'div-percent-bar-wrapper';

    const eBar = document.createElement('div');
    eBar.className = 'div-percent-bar';
    eBar.style.height = '100%';
    eBar.style.width = '0%';
    eBar.style.display = 'flex';
    eBar.style.backgroundColor = '#5ECFFF';
    eBar.style.transition = 'width 0.1s';
    this.eBar = eBarWrapper.appendChild(eBar);

    // Set the color of the bar chart
    const belowColor = config.belowColor ?? (config.lowerBound ? '#E15759' : '#59A14F');
    const aboveColor = config.aboveColor ?? (config.upperBound ? '#E15759' : '#59A14F');
    const inColor = config.inColor ?? '#59A14F';

    const isGrandTotal = params.node.level === -1;

    let bgColor = '';
    if (config.gradient && config.lowerBound && config.upperBound) {
      let colorLerp = interpolateHsl(belowColor, aboveColor);
      bgColor = colorLerp(unlerp(Number(config.lowerBound), Number(config.upperBound), value));
    } else {
      bgColor =
        value < Number(config.lowerBound)
          ? belowColor
          : !config.upperBound || value <= Number(config.upperBound)
            ? inColor || aboveColor
            : aboveColor;
    }

    if (isGrandTotal) {
      this.eBar.style.display = 'none';
    } else {
      eBar.style.backgroundColor = bgColor;
    }

    const eValue = document.createElement('div');
    eValue.className = 'div-percent-value';
    eValue.style.color = config.useCustomFontColor ? (config.customFontColor ?? '#666') : '#666';
    eValue.innerHTML = params.valueFormatted ?? params.value;

    this.eGui.className = 'div-outer-div';
    this.eGui.appendChild(eBar);
    this.eGui.appendChild(eValue);

    this.debounceUpdateBarchartBounds(params.context.colAggData ?? BarchartCellRenderer.colAggData);
  }

  getGui(): HTMLElement {
    return this.eGui;
  }

  debounceUpdateBarchartBounds = debounce(this.updateBarchartBounds, 250);

  refresh(params: BarchartCellRendererParams): boolean {
    this.debounceUpdateBarchartBounds(params.context.colAggData ?? {});
    return true;
  }
}

/**
 * Bounded inverse linear interpolation
 *
 * @param start - The lower bound of the range
 * @param end - The upper bound of the range
 * @param x - The value to interpolate
 * @returns The value in the range [0, 1] that corresponds to x
 */
export function unlerp(start: number, end: number, x: number): number {
  // Swap start and end if start is greater than end
  if (start > end) [start, end] = [end, start];

  if (x <= start) return 0;
  if (x >= end) return 1;
  return (x - start) / (end - start);
}
