import { Column, GridApi, IFilter, ProvidedFilter } from 'ag-grid-community';

export async function setAgFilter(
  api: GridApi,
  column: Column,
  filter: Promise<IFilter | null | undefined>,
  value: any,
  { exclude = false } = {}
) {
  const filterInstance = await filter;
  if (!(filterInstance instanceof ProvidedFilter)) return; // make ts happy

  // get value formatted for filtering
  let valueGetter = column.getColDef().filterValueGetter;
  if (typeof valueGetter === 'function') {
    let params: any = { column, getValue: () => value, value };
    value = valueGetter(params);
  }

  /**
   * note that SetFilters support multiple excluded values while "regular"
   * filters can exclude/include a single value at a time. Technically we can
   * use condition1, condition2 and operator to allow excluding of up to two
   * values at a time but I don't think that is worth the complexity.
   *
   * TODO: this should be relatively simple using setModel.
   */
  const dateToISOString = (date: Date) => new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();

  switch (column.getColDef().cellDataType) {
    case 'text':
      filterInstance.setModel(makeFilterModel('text', exclude ? 'notEqual' : 'equals', value));
      break;
    case 'number':
      filterInstance.setModel(makeFilterModel('number', exclude ? 'notEqual' : 'equals', Number(value)));
      break;
    case 'date':
    case 'datetime':
      filterInstance.setModel(makeFilterModel('date', exclude ? 'notEqual' : 'equals', dateToISOString(value)));
      break;
    default:
      break;
  }

  // trigger update of filtered values
  api.onFilterChanged();
}

export async function clearAgFilter(api: GridApi, filter: Promise<IFilter | null | undefined>) {
  const filterInstance = await filter;
  if (!(filterInstance instanceof ProvidedFilter)) return; // make ts happy

  // Setting the model to null changes the filter to "no filter"
  filterInstance.setModel(null);

  // trigger update of filtered values
  api.onFilterChanged();
}

function makeFilterModel(filterType: string, type: string, filterValue: any) {
  return {
    filterType: filterType,
    operator: 'OR',
    conditions: [
      {
        filterType: filterType,
        type,
        ...(filterType === 'date' ? { dateFrom: filterValue } : { filter: filterValue }),
      },
    ],
  };
}
