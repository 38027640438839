import { PivotStatusBarComponent } from '@/components/PivotStatusBar';
import { removeSpaces } from '@/grid/mapping/utils';
import { Settings } from '@/settings';
import { compose } from '@/utils/functional';
import {
  excelRowHeightGetter,
  expandAllButLastNGroups,
  formatWithValueFormatter,
  importLocale,
  isFirstColumn,
  prepareGroupedColumnValues,
} from '@/utils/grid';
import {
  ColDef,
  ColumnFunctionCallbackParams,
  ExcelStyle,
  GridApi,
  GridOptions,
  SideBarDef,
  StatusPanelDef,
  ToolPanelDef,
} from 'ag-grid-community';
import { interpolateHsl } from 'd3-interpolate';
import formatDate from 'date-fns/format';
import { gridOptions } from '..';
import { createTextInterpolator } from '../../calculation';
import { unlerp } from '../../cellRenderers/barChartCellRenderer';
import imageCellRenderer from '../../cellRenderers/imageCellRenderer';
import FormulasToolPanel from '../../formulasToolPanel';
import { getAutoGroupColumnDef } from './autoGroupColumnDef';
import { applyRowClass, applyRowStyle } from './styleFunctions';
import { rgbToHex } from './utils';
/**
 * This function applies settings to ag grids gridOptions.
 * Note that this also needs to undo any options it might have previously set.
 */

export function applySettingsToGridOptions(gridOptions: GridOptions, settings: Settings, gridApi?: GridApi) {
  let isAuthoring = window.tableau.extensions.environment.mode === 'authoring';

  gridOptions.groupAllowUnbalanced = settings.groupUseUnbalancedGroups;
  gridOptions.groupDisplayType = settings.showGroupMultiColumn ? 'multipleColumns' : 'singleColumn';
  gridOptions.showOpenedGroup = settings.showOpenedGroup;
  gridOptions.suppressAggFuncInHeader = true;
  gridOptions.suppressFieldDotNotation = true;
  gridOptions.enableAdvancedFilter = settings.enableAdvancedFilter;
  gridOptions.includeHiddenColumnsInAdvancedFilter = settings.includeHiddenColumnsInAdvancedFilter;
  gridOptions.advancedFilterBuilderParams = {
    showMoveButtons: true,
  };
  // disable animations when enhanced accessibility mode has been enabled
  gridOptions.animateRows = settings.useAccessibilityMode ? false : true;
  gridOptions.rowHeight = settings?.autoRowHeight
    ? undefined
    : settings.showCompactMode
      ? 22
      : settings.rowHeight
        ? settings.rowHeight
        : undefined;
  gridOptions.pivotHeaderHeight = settings.showPivotHeader ? undefined : 0;
  gridOptions.statusBar = settings.showStatusBar ? statusBarDef : undefined;
  gridOptions.rowMultiSelectWithClick = settings.rowMultiSelectWithClick;
  gridOptions.rowSelection = settings.rowMultiSelectEnabled ? 'multiple' : 'single';
  gridOptions.enableRangeSelection = settings.rowMultiSelectEnabled;
  gridOptions.enableCharts = settings.charting;
  gridOptions.enableRtl = settings.enableRtl;
  gridOptions.pagination = settings.enablePagination;
  gridOptions.paginationAutoPageSize = settings.paginationPageSize ? false : true;
  gridOptions.paginationPageSize = settings.paginationPageSize ? settings.paginationPageSize : 100;
  gridOptions.groupHideOpenParents = settings.showGroupMultiColumn && settings.groupHideOpenParents;
  gridOptions.suppressGroupRowsSticky = settings.suppressGroupRowsSticky;
  gridOptions.pivotRowTotals = settings.enablePivotRowTotals ? 'after' : undefined;

  gridOptions.groupIncludeFooter = settings.showGroupIncludeFooter;
  gridOptions.groupIncludeTotalFooter = settings.showGroupIncludeTotalFooter && !settings.showGroupTotalAtTop;

  gridOptions.tooltipShowDelay = settings.tooltipShowDelay * 1000;
  gridOptions.tooltipHideDelay = settings.tooltipHideDelay * 1000;
  gridOptions.tooltipMouseTrack = settings.tooltipMouseTrack;
  gridOptions.tooltipInteraction = settings.tooltipInteraction;

  gridOptions.suppressCsvExport = (!settings.userCanExport || !settings.CSVExport) && !isAuthoring;
  gridOptions.suppressExcelExport = (!settings.userCanExport || !settings.ExcelExport) && !isAuthoring;
  if (!settings.userCanCopy) {
    // inspiration: https://www.ag-grid.com/javascript-data-grid/clipboard/
    gridOptions.sendToClipboard = function (params) {
      params.data = '';
      return '';
    };
    gridOptions.processCellForClipboard = function () {
      return '';
    };
  }

  // processSecondaryColGroupDef deprecated in 28.0
  gridOptions.processPivotResultColGroupDef = (colGroupDef) => {
    // @ts-ignore
    let headerName = colGroupDef.headerName || '';
    // @ts-ignore
    let dateFormat = settings.pivotDateFormat ? settings.pivotDateFormat : 'YYYY-MM';

    // @ts-ignore
    function isValidDate(value) {
      var dateWrapper = new Date(value);
      return !isNaN(dateWrapper.getDate());
    }
    if (isValidDate(headerName) && settings.pivotHeaderIsDate) {
      colGroupDef.headerName = formatDate(headerName, dateFormat);
    } else {
      colGroupDef.headerName = colGroupDef.headerName;
    }
  };

  let sideBarDef: SideBarDef = {};
  sideBarDef.toolPanels = [];

  if (settings.showColumnsPanel) {
    sideBarDef.toolPanels.push(createColumnsPanel(settings));
  }
  //TODO: Fix this.
  if (settings.showFiltersPanel) {
    sideBarDef.toolPanels.push(filtersPanel);
  }

  if (settings.showFormulasPanel) {
    sideBarDef.toolPanels.push(formulasPanel);
  }

  gridOptions.sideBar = settings.showSideBar ? sideBarDef : false;

  gridOptions.rowGroupPanelShow = settings.showGroupBar ? 'always' : 'never';

  gridOptions.getRowClass = applyRowClass();
  gridOptions.getRowStyle = applyRowStyle(settings);

  const autoRowHeightDef = {
    ...gridOptions.defaultColDef,
    checkboxSelection: (params: any) => (settings.showCheckbox ? isFirstColumn(params) : false),
    headerCheckboxSelection: (params: any) => (settings.showCheckbox ? isFirstColumn(params) : false),
    headerCheckboxSelectionFilteredOnly: true,
  };
  gridOptions.defaultColDef = settings.showCheckbox
    ? firstColCheckBoxDef
    : settings.autoRowHeight
      ? autoRowHeightDef
      : defaultColDef;

  // tbv groupregel selecteren als event
  gridOptions.groupSelectsChildren = settings.showCheckbox || settings.autoRowHeight ? true : false;
  gridOptions.ensureDomOrder = settings.useAccessibilityMode ? true : false;
  gridOptions.suppressColumnVirtualisation = settings.useAccessibilityMode ? true : false;
  gridOptions.suppressRowVirtualisation = settings.useAccessibilityMode ? true : false;

  const rowHeightGetters: { [key: string]: (params: any) => number } = {};

  gridOptions.defaultExcelExportParams = {
    skipRowGroups: !settings.exportRowGroups,
    skipColumnGroupHeaders: !settings.exportColumnGroups,
    // @ts-ignore
    sheetName: 'Sheet 1', //This seems to not be used
    rowHeight: excelRowHeightGetter(gridOptions, settings, undefined, gridApi),
    addImageToCell: function (rowIndex, column, value) {
      if (
        settings.exportIncludeImages &&
        column.getColDef().cellRenderer === imageCellRenderer &&
        value?.startsWith('data:image')
      ) {
        const colId = column.getColId();
        rowHeightGetters[colId] ??= excelRowHeightGetter(gridOptions, settings, colId, gridApi);

        return {
          image: {
            id: rowIndex + colId,
            base64: value,
            imageType: 'png',
            width: (column.getActualWidth() / 3) * 4,
            height: (rowHeightGetters[colId]({ rowIndex }) / 3) * 4,
          },
        };
      }
    },
    skipHeader: !settings.exportIncludeHeader,
    allColumns: !settings.exportVisibleColumns,
    fileName: settings.ExcelFileName,
    autoConvertFormulas: true,
  };

  gridOptions.defaultCsvExportParams = {
    skipRowGroups: !settings.exportRowGroups,
    skipColumnGroupHeaders: !settings.exportColumnGroups,
    skipColumnHeaders: !settings.exportIncludeHeader,
    allColumns: !settings.exportVisibleColumns,
    fileName: settings.CSVFileName,
  };

  gridOptions.defaultExcelExportParams.shouldRowBeSkipped = (params) => {
    if (settings.exportTopNRows && params?.node?.rowIndex && params?.node?.rowIndex >= settings.exportTopNRows) {
      return true;
    }

    if (settings.exportAllButLastOne) {
      // when groupHideOpenParents is ON, there are no levels in node data so we cannot skip last level
      if (settings.groupHideOpenParents) {
        return false;
      }

      let length = params.api.getRowGroupColumns().length;
      if (params.node.level >= length && length >= 1) {
        return true;
      }
    }

    return false;
  };

  gridOptions.defaultCsvExportParams.shouldRowBeSkipped = (params) => {
    if (settings.exportTopNRows && params.node.rowIndex && params.node.rowIndex >= settings.exportTopNRows) {
      return true;
    }

    if (settings.exportAllButLastOne) {
      // when groupHideOpenParents is ON, there are no levels in node data so we cannot skip last level
      if (settings.groupHideOpenParents) {
        return false;
      }

      let length = params.api.getRowGroupColumns().length;

      if (params.node.level >= length && length >= 1) {
        return true;
      }
    }

    return false;
  };

  gridOptions.defaultExcelExportParams.processCellCallback = (params) => {
    // Excel export needs the ISO date
    // Check if value is the tableau null value
    if (params.value === '%null%') return null;
    // Check if value is a date
    if (params.value && params.value.toISOString) {
      // Remove the tableau timzone offset from the date
      const d = new Date(params.value.getTime() - params.value.getTimezoneOffset() * 60000);
      // If the date is a valid date, return the ISO date
      if (d instanceof Date && !isNaN(d as any)) {
        return d.toISOString();
      } else {
        // If the date is not valid, return null
        return null;
      }
    }
    let config = settings.columnConfig[params.column.getColId()];
    let columns = params.api.getColumns()?.map((column) => column.getColDef());

    if (config && config.urlExpression && columns) {
      let url = createTextInterpolator(config.urlExpression, columns);
      if (params.node?.data) {
        let interpolatedUrl = url?.(params.node.data);
        if (params.value) {
          return `=HYPERLINK("${interpolatedUrl}", "${params.formatValue(params.value) ?? params.value}")`;
        }
      }
    }

    if (settings.exportIncludeImages && params.value && params.column.getColDef().cellRenderer === imageCellRenderer) {
      if (params.context?.base64?.[params.value]) return params.context.base64[params.value];
    }

    // If the value is not a date or urlexpression column, return default formatter
    // if the value contains a value property, then it is a calculation
    return params?.value?.value || params?.value;
  };

  if (settings.shouldFormatExport) {
    // @ts-ignore
    gridOptions.defaultCsvExportParams.processCellCallback = compose(
      formatWithValueFormatter,
      prepareGroupedColumnValues
    );
  } else {
    // @ts-ignore
    gridOptions.defaultCsvExportParams.processCellCallback = compose(
      (params) => (params.value === '%null%' ? null : params.value),
      prepareGroupedColumnValues
    );
  }

  if (settings.autoExpandGroups) {
    gridOptions.onColumnRowGroupChanged = (params) => {
      expandAllButLastNGroups(params.api, settings.expandAllButLastNGroups);
    };
    gridOptions.onRowDataUpdated = (params) => {
      expandAllButLastNGroups(params.api, settings.expandAllButLastNGroups);
    };
  } else {
    gridOptions.onColumnRowGroupChanged = undefined;
    gridOptions.onRowDataUpdated = undefined;
  }

  gridOptions.defaultExcelExportParams.processRowGroupCallback = rowGroupCallback;

  //Give default color to the column
  const verticalAlignment =
    settings.autoRowHeight === true ? 'flex-start' : (settings.verticalAlignment ?? 'flex-start');
  let font = { size: 11 };
  gridOptions.excelStyles = [
    {
      id: 'header',
      font: {
        color: '#000000',
        size: 11,
        bold: true,
      },
      interior: {
        color: '#ffffff',
        pattern: 'Solid',
      },
    },
    {
      id: 'cell',
      font: {
        color: '#000000',
      },
      interior: {
        color: '#ffffff',
        pattern: 'Solid',
      },
      alignment: {
        vertical: verticalAlignment === 'Top' ? 'Top' : verticalAlignment === 'Center' ? 'Center' : 'Bottom',
      },
    },
    { id: 'exportStyle', font },
    { id: 'isString', dataType: 'String' },
    {
      id: 'groupRows',
      font: {
        bold: true,
      },
      interior: {
        color: '#f5f6f7',
        pattern: 'Solid',
      },

      borders: {
        borderBottom: {
          color: '#000000',
          lineStyle: 'Continuous',
          weight: 1,
        },
        borderTop: {
          color: '#d9dcde',
          lineStyle: 'Continuous',
          weight: 1,
        },
        borderRight: {
          color: '#d9dcde',
          lineStyle: 'Continuous',
          weight: 1,
        },
        borderLeft: {
          color: '#d9dcde',
          lineStyle: 'Continuous',
          weight: 1,
        },
      },
    },
    {
      id: 'borders',
      borders: {
        borderBottom: {
          color: '#d9dcde',
          lineStyle: 'Continuous',
          weight: 1,
        },
        borderTop: {
          color: '#d9dcde',
          lineStyle: 'Continuous',
          weight: 1,
        },
        borderRight: {
          color: '#d9dcde',
          lineStyle: 'Continuous',
          weight: 1,
        },
        borderLeft: {
          color: '#d9dcde',
          lineStyle: 'Continuous',
          weight: 1,
        },
      },
    },
    {
      id: 'cell-align-left',
      alignment: {
        horizontal: 'Left',
      },
    },
    {
      id: 'cell-align-center',
      alignment: {
        horizontal: 'Center',
      },
    },
    {
      id: 'cell-align-right',
      alignment: {
        horizontal: 'Right',
      },
    },
    {
      id: 'ag-left-aligned-header',
      alignment: {
        horizontal: 'Left',
      },
    },
    {
      id: 'ag-center-aligned-header',
      alignment: {
        horizontal: 'Center',
      },
    },
    {
      id: 'ag-right-aligned-header',
      alignment: {
        horizontal: 'Right',
      },
    },
    {
      id: 'url-cell',
      font: {
        underline: 'Single',
        color: '#358ccb',
      },
    },
  ];

  // push 20 group indent styles
  for (let i = 1; i <= 50; i++) {
    gridOptions.excelStyles.push({
      id: `indent-${i}`,
      alignment: {
        indent: (i - 1) * 2 || 0,
      },
      // note, dataType: 'string' required to ensure that numeric values aren't right-aligned
      dataType: 'String',
    });
  }

  // styles for totals and subtotals (conditional color take precedence over theme config)
  // TODO: Possible feature to allow different sizes of font for totals and subtotals, instead of the standard 11.
  gridOptions.excelStyles.push({
    id: 'total-group-row-cell',
    font: {
      color: settings.themeConfig.totalsTextColor ?? '#000000',
    },
    interior: {
      color: settings.themeConfig.totalsBackgroundColor ?? '#ffffff',
      pattern: 'Solid',
    },
  });
  gridOptions.excelStyles.push({
    id: 'subtotal-group-row-cell',
    font: {
      color: settings.themeConfig.subtotalTextColor ?? '#000000',
    },
    interior: {
      color: settings.themeConfig.subtotalBackgroundColor ?? '#ffffff',
      pattern: 'Solid',
    },
  });

  // styles for column header groups
  for (const [columnGroupId, columnGroupConfig] of Object.entries(settings.columnGroupConfig)) {
    const uniqueCssClassname = `--ag-group-header-${columnGroupId.replace(/[^a-zA-Z0-9-_]/g, '')}`;

    gridOptions.excelStyles.push({
      id: uniqueCssClassname,
      interior: {
        color: columnGroupConfig?.ColumnGroupBackgroundColor ?? '#ffffff',
        pattern: 'Solid',
      },
      font: {
        color: columnGroupConfig?.ColumnGroupTextColor ?? '#000000',
      },
    });
  }

  for (const [dimension, column] of Object.entries(settings.columnConfig)) {
    if (!column || !('color' in column)) continue;

    let fieldName = column.fieldName ?? dimension;
    const headerClassName = `excel-header-${fieldName.replace(/[^a-zA-Z0-9-_]/g, '')}`;
    const columnIdForExcelStyle = removeSpaces(fieldName + '_ColumnStyle');

    // conditional color formatting
    if (column.color.type === 'discrete' && 'map' in column.color) {
      for (const [member, color] of Object.entries(column.color.map)) {
        let style: ExcelStyle = {
          id: removeSpaces(dimension + '_' + String(member)),
        };
        if ('colorCellBackground' in column && column.colorCellBackground === false) {
          style['font'] = {
            color,
          };
        } else {
          var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color) || [];
          let backgroundColorArr = [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)];
          var col = Math.round(
            (backgroundColorArr[0] * 299 + backgroundColorArr[1] * 587 + backgroundColorArr[2] * 114) / 1000
          );
          let fore = col > 125 ? '#666666' : '#ffffff';

          style['interior'] = {
            color,
            pattern: 'Solid',
          };
          style['font'] = {
            color: fore,
          };
        }

        gridOptions.excelStyles.push(style);
      }
    } else if (column.color.type === 'continuous') {
      if (!column.color.lowerBound && !column.color.upperBound) continue;
      let { belowColor = '#e15759', inColor = '#59a14f', aboveColor = '#e15759', gradient = false } = column.color;

      // ensure either bound is not an empty string as that will
      // evaluate to 0 when compared to a number...
      let lowerBound =
        column.color.lowerBound === undefined || column.color.lowerBound === ''
          ? undefined
          : Number(column.color.lowerBound);
      let upperBound =
        column.color.upperBound === undefined || column.color.upperBound === ''
          ? undefined
          : Number(column.color.upperBound);
      const isFullColorRange = upperBound !== undefined && lowerBound !== undefined;

      let colorLerp = interpolateHsl(belowColor, aboveColor);

      belowColor = lowerBound === undefined ? inColor : belowColor;
      inColor = lowerBound === undefined ? aboveColor : inColor;
      gradient = lowerBound === undefined || upperBound === undefined ? false : gradient;
      let range = upperBound === undefined || lowerBound === undefined ? 1 : upperBound - lowerBound;
      let step = upperBound === undefined || lowerBound === undefined ? 1 : (upperBound - lowerBound) / 10;
      step = step === 0 ? 1 : step;
      let start = upperBound === undefined || lowerBound === undefined ? 0 : lowerBound;
      lowerBound = upperBound === undefined || lowerBound === undefined ? 0 : lowerBound;

      for (let j = start; j <= range + start + step; j = j + step) {
        let jj = typeof j === 'number' && !Number.isNaN(j) ? Math.round(j * 100) / 100 : j;
        let columnIdForExcelStyle = removeSpaces(dimension + '_' + String(jj));
        let style: ExcelStyle = {
          id: columnIdForExcelStyle,
        };

        let backgroundColor = inColor;
        if (jj <= lowerBound && !isFullColorRange) {
          backgroundColor = belowColor;
        }
        if (upperBound && jj >= upperBound && !isFullColorRange) {
          backgroundColor = aboveColor;
        }
        if (jj <= lowerBound && isFullColorRange) {
          backgroundColor = belowColor;
          style['id'] = dimension + '_' + 'lower';
        }
        if (upperBound && jj >= upperBound && isFullColorRange) {
          backgroundColor = aboveColor;
          style['id'] = dimension + '_' + 'upper';
        }

        if (
          gradient &&
          lowerBound !== null &&
          lowerBound !== undefined &&
          upperBound !== null &&
          upperBound !== undefined &&
          typeof lowerBound === 'number' &&
          typeof upperBound === 'number'
        ) {
          backgroundColor = colorLerp(unlerp(lowerBound, upperBound, j));
        }

        if ('colorCellBackground' in column && column.colorCellBackground === false) {
          style['font'] = {
            color: gradient
              ? rgbToHex(
                  backgroundColor
                    .substring(4, backgroundColor.length - 1)
                    .replace(/ /g, '')
                    .split(',')
                )
              : backgroundColor,
          };
          // If there is a default background color, use it
          if (column.color.backgroundColor) {
            style['interior'] = {
              color: column.color.backgroundColor,
              pattern: 'Solid',
            };
          }
        } else {
          let fore;
          if (column.color.useCustomFontColor && column.color?.customFontColor) {
            fore = column.color.customFontColor;
          } else {
            var result =
              /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
                gradient
                  ? rgbToHex(
                      backgroundColor
                        .substring(4, backgroundColor.length - 1)
                        .replace(/ /g, '')
                        .split(',')
                    )
                  : backgroundColor
              ) || [];
            let backgroundColorArr = [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)];
            var col = Math.round(
              (backgroundColorArr[0] * 299 + backgroundColorArr[1] * 587 + backgroundColorArr[2] * 114) / 1000
            );
            fore = col > 125 ? '#666666' : '#ffffff';
          }
          style['font'] = {
            color: fore,
          };
          style['interior'] = {
            color: gradient
              ? rgbToHex(
                  backgroundColor
                    .substring(4, backgroundColor.length - 1)
                    .replace(/ /g, '')
                    .split(',')
                )
              : backgroundColor,
            pattern: 'Solid',
          };
        }
        gridOptions.excelStyles.push(style);
      }
    }
    //Give Font color if exists
    if (column.color.fontColor) {
      let style = {
        id: columnIdForExcelStyle,
        font: {
          color: column.color.fontColor,
        },
      };

      gridOptions.excelStyles.push(style);
    }
    //Give Background color if exists
    if (column.color.backgroundColor) {
      gridOptions.excelStyles.push({
        id: columnIdForExcelStyle,
        interior: {
          color: column.color.backgroundColor,
          pattern: 'Solid',
        },
      });
    }

    // Give header background color if exists
    if (column.color.headerBackgroundColor) {
      gridOptions.excelStyles.push({
        id: headerClassName,
        interior: {
          color: column.color.headerBackgroundColor,
          pattern: 'Solid',
        },
      });
    }

    // Give header font color if exists
    if (column.color.headerFontColor) {
      gridOptions.excelStyles.push({
        id: headerClassName,
        font: {
          color: column.color.headerFontColor,
        },
      });
    }
  }

  gridOptions.alwaysAggregateAtRootLevel = true;
  gridOptions.autoGroupColumnDef = getAutoGroupColumnDef(gridOptions, settings);
  //get the promise value
  importLocale().then((localeText) => {
    gridOptions.localeText = localeText ?? {};
  });

  if (settings.rowColorColumn) {
    let config = settings.columnConfig[settings.rowColorColumn];
    gridOptions.suppressRowHoverHighlight = config ? config.colorCellBackground : false;
  }
}

/**
 * Default Column Definitions
 * */
const defaultColDef: ColDef = {
  sortable: true,
  resizable: true,
  filterParams: {
    buttons: ['clear', 'reset'],
  },
  headerComponentParams: {
    menuIcon: 'fa-bars',
    template: `
      <div class="ag-cell-label-container" role="presentation">
                  <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
                  <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
                  <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
                    <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>
                    <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>
                    <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>
                    <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>
                    <span data-ref="eText" class="ag-header-cell-text  wrap-text" role="columnheader"></span>
                    <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
                  </div>
                </div>
      `,
  },
};

/**
 * Used for showRowSelectCheckbox setting
 */
const firstColCheckBoxDef: ColDef = {
  ...defaultColDef,
  checkboxSelection: (params: any) => isFirstColumn(params),
  headerCheckboxSelection: (params: any) => isFirstColumn(params),
  headerCheckboxSelectionFilteredOnly: true,
};

const statusBarDef: { ['statusPanels']: StatusPanelDef[] } = {
  statusPanels: [
    { statusPanel: PivotStatusBarComponent, align: 'left', key: 'pivotStatusBar' },
    { statusPanel: 'agTotalRowCountComponent', align: 'left' },
    { statusPanel: 'agFilteredRowCountComponent', align: 'left' },
    { statusPanel: 'agAggregationComponent', align: 'right' },
  ],
};

/**
 * repeating the defaults just to make sure the sidebar
 * doesn't open when ag-grid is first loaded
 */

const createColumnsPanel: (settings: any) => ToolPanelDef = (settings) => ({
  id: 'columns',
  labelDefault: 'Columns',
  labelKey: 'columns',
  iconKey: 'columns',
  toolPanel: 'agColumnsToolPanel',
  toolPanelParams: {
    suppressSyncLayoutWithGrid: settings.groupColumnsPanel as boolean,
    suppressColumnMove: settings.groupColumnsPanel as boolean,
  },
});

const formulasPanel = {
  id: 'formulas',
  labelDefault: 'Formulas',
  labelKey: 'formulas',
  iconKey: 'valuePanel',
  toolPanel: FormulasToolPanel,
  minWidth: 350,
  width: 350,
};

const filtersPanel = {
  id: 'filters',
  labelDefault: 'Filters',
  labelKey: 'filters',
  iconKey: 'filter',
  toolPanel: 'agFiltersToolPanel',
};

function rowGroupCallback(params: ColumnFunctionCallbackParams) {
  // This is for the Excel export
  const row = params.node;
  const rowName = row.key;
  const isRootLevel = row.level === -1; // Grand Total
  if (isRootLevel) {
    if (gridOptions.localeText?.total) {
      return gridOptions.localeText.total;
    } else {
      return 'Total';
    }
  }

  if (row.group && row.footer) {
    // this is a sub total row, show sub total label
    if (gridOptions.localeText?.subTotal) {
      return `${gridOptions.localeText.subTotal} ${rowName}`;
    } else {
      return `Sub total ${rowName}`;
    }
  }
  return rowName ?? '';
}
