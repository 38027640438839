import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community';
import Showdown from 'showdown';

export default class MarkdownCellRenderer implements ICellRendererComp {
  eGui!: HTMLElement;

  init({ value }: ICellRendererParams): void {
    this.eGui = document.createElement('div');

    if (!value) return;

    const md = new Showdown.Converter();
    this.eGui.innerHTML = md.makeHtml(value);
  }

  getGui(): HTMLElement {
    return this.eGui;
  }

  refresh(): boolean {
    return true;
  }
}
