// @ts-check
import { initializeApp } from '@/main';
import type { Settings } from '@/settings';
import { ConfigMap, FormulaType } from '@/types/settings';
import type { ColumnState, GridOptions } from 'ag-grid-community';
import { GridApi, createGrid } from 'ag-grid-community';
import { gridApi, setGridApi } from '.';
import { getSettings } from '../settings';
import { EventBus } from '../utils/event-bus';
import { applyGridState, getParameterValue, setParameterValue } from '../utils/grid-state';
import { FETCH_METHODS, openConfig, removeAgg } from '../utils/tableau';
import { isMeasure } from './mapping/utils';
import { exportCSV, exportExcel, exportPDF } from './menu';
import { createPopper } from '@popperjs/core';

type CustomGridOptions = {
  updateColumnDefs: boolean;
  theme: 'light' | 'dark' | 'tableau' | 'alpine' | 'alpine-dark';
  showCompactMode: boolean;
  useTableauFont: boolean;
  fetchMethod: typeof FETCH_METHODS;
  groupColumnsPanel: boolean;
};

export type GridState = {
  columnState?: ColumnState[];
  columnGroupState?: any[];
  pivotMode?: any;
  sortModel?: any;
  filterModel?: any;
  valueColumns?: [];
  formulaColumns?: FormulaType[];
};

type AlertStyle = 'info' | 'danger' | 'warning' | 'success' | 'dark' | 'light';

type ShowGrid = {
  type: 'grid';
  gridOptions: GridOptions;
  gridState: GridState;
  options: CustomGridOptions;
  gridApi?: GridApi;
};

type ShowLoading = {
  type: 'loading';
};

type ShowConfigure = {
  type: 'configure';
};

type ShowButton = {
  type: 'button';
};

type ShowLanding = {
  type: 'landing';
};

type ShowAlert = {
  type: 'alert';
  heading: string;
  message: string;
  style: AlertStyle;
};

type View = ShowGrid | ShowLoading | ShowConfigure | ShowButton | ShowAlert | ShowLanding;

/**
 * getElement returns the element or throws an error
 * this is mostly to convince typescript that these are not going to be null
 */
function getElement(id: string): HTMLElement {
  let el = document.getElementById(id);
  if (el === null) {
    throw new Error(`Could not find element with id ${id}`);
  }
  return el;
}

const loadingView = getElement('loading');
const alertView = getElement('alert');
const configureView = getElement('landing-page-container');
const gridView = getElement('grid');
const buttonView = getElement('extensionTriggerButton');
const landingView = getElement('landing-page-container');
const views = [loadingView, alertView, configureView, gridView, buttonView, landingView];

const alertHeadingEl = getElement('alert-heading');
const alertMessageEl = getElement('alert-message');
const errorHelpEl = getElement('error-help');
const brandedSupportEl = getElement('branded-support');
const removeFormulasContainer = getElement('remove-formulas');
const removeFormulasButton = getElement('remove-formulas-button');
const removeFormulasMessage = getElement('remove-formulas-message');
const freeBanner = getElement('free-banner');
const dismissToasterButtonForExport = getElement('dismissToasterButtonForExport');
const reconfigureButton = getElement('reconfigure-button');

function attachEventHandlers() {
  dismissToasterButtonForExport.addEventListener('click', dismissToasterForExport);
  reconfigureButton.addEventListener('click', openConfig);
}

export const initLandingPage = (inTableauEnvironment: boolean) => {
  const extensionVersionElem = getElement('extension-version-label');
  extensionVersionElem.innerText = import.meta.env.VITE_VERSION;

  if (!inTableauEnvironment) {
    // unsupported environment
    const unSupportedEnvironment = getElement('unsupported-card');
    unSupportedEnvironment.style.display = 'flex';
    return;
  }

  const viewerCard = getElement('viewer-card');
  const PRODUCT = import.meta.env.VITE_PRODUCT;
  const MODE = window.tableau.extensions.environment.mode;

  if (PRODUCT === 't' && MODE !== 'authoring') {
    const title = getElement('supertables-title');
    title.innerText = 'SuperTables Free';
    viewerCard.style.display = 'flex';
    loadingView.style.display = 'none';
    configureView.style.display = '';
    return;
  }

  const newToSuperTablesCard = getElement('new-card');
  newToSuperTablesCard.style.display = 'flex';
  newToSuperTablesCard.classList.add('transparent');
  const gifCard = getElement('gif-card');
  gifCard.style.display = 'flex';

  const imageElementInGifCard: HTMLImageElement | null = gifCard.querySelector('img');

  const isVizExtension = !!window.tableau.extensions.worksheetContent;

  if (!isVizExtension) {
    const configureButton = getElement('configure-button');
    const dashboardGettingStarted = getElement('dashboard-card');

    configureButton.addEventListener('click', openConfig);
    dashboardGettingStarted.style.display = 'flex';

    if (imageElementInGifCard) {
      imageElementInGifCard.src = '/gifs/dashboardTutorial.gif';
    }
  } else if (isVizExtension && imageElementInGifCard) {
    const vizExtensionGettingStarted = getElement('viz-card');
    vizExtensionGettingStarted.style.display = 'flex';
    if (imageElementInGifCard) {
      imageElementInGifCard.src = '/gifs/vizExtensionTutorial.gif';
    }
  }
};

function dismissToasterForExport() {
  getElement('exportGenerate').style.display = 'none';
}
// implicit stuff that happens when this file is imported... bad!
attachEventHandlers();

export function hideLoadingProgress() {
  const element = getElement('loading-container');
  if (element.style.display !== 'none') element.style.display = 'none';
}

export function showLoadingProgress() {
  const element = getElement('loading-container');
  if (element.style.display !== 'flex') element.style.display = 'flex';
}

export function setLoadingProgress(progress: number) {
  showLoadingProgress();
  getElement('loading-progress').textContent = `${Math.round(progress)}`;
  // getElement('loading-bar-progress').style.width = `${progress}%`;
}

export function setProcessBar(show: boolean, color = undefined) {
  if (color) {
    setProcessBarColor(color);
  }
  getElement('process-bar').style.display = show ? '' : 'none';
}

function setProcessBarColor(color: string) {
  getElement('process-bar').style.background =
    `repeating-linear-gradient(to right, ${color} 0%, ${color} 30%, white 50%, ${color} 70%, ${color} 100%)`;
}

/**
 * showAGGrid shows the main grid interface
 */
export function showAGGrid(gridOptions: GridOptions, gridState: GridState, options: any) {
  showView({ type: 'grid', gridOptions, gridState, options, gridApi });
}

export function showLoading() {
  showOnlyView(loadingView);
}

/**
 * showConfigure show the configure button
 */
export function showConfigure() {
  showView({ type: 'configure' });
}

export function showButton() {
  showView({ type: 'button' });
}

export function showLanding() {
  showView({ type: 'landing' });
}

/**
 * showAlert will show an alert with a given heading, message and style
 */
export function showAlert(heading: string, message = '', style: AlertStyle = 'light') {
  showView({ type: 'alert', heading, message, style });
}

/**
 * showError will diplay an alert with an error message
 */
export function showError(error: Error) {
  console.error(error);
  showAlert('Oh No! There was a problem', error.message, 'danger');
}

function showView(view: View) {
  switch (view.type) {
    case 'landing':
      showOnlyView(landingView);
      return;

    case 'loading':
      loadingView.style.display = '';
      return;

    case 'configure':
      showOnlyView(configureView);
      return;

    case 'grid':
      updateAGGrid(view.gridOptions, view.gridState, view.options, view.gridApi);
      showOnlyView(gridView);
      setLoadingProgress(0);
      return;

    case 'button':
      showOnlyView(buttonView);
      return;

    case 'alert':
      alertView.className = `alert alert-${view.style}`;
      alertHeadingEl.textContent = view.heading;
      alertMessageEl.textContent = view.message;
      showIf(view.style === 'danger', errorHelpEl);
      // @ts-ignore
      let mode = window.tableau.extensions.environment.mode;
      showIf(mode === 'authoring', brandedSupportEl);
      showOnlyView(alertView);
      return;

    default:
      console.error('unknown view type', view);
  }
}

function showIf(test: boolean, el: HTMLElement) {
  el.style.display = test ? '' : 'none';
}

function showOnlyView(viewToShow: HTMLElement) {
  for (let view of views) {
    showIf(view === viewToShow, view);
  }

  // @ts-ignore
  if (import.meta.env.VITE_PRODUCT === 't' && window.tableau.extensions.environment.mode === 'authoring') {
    freeBanner.style.display = 'flex';
  }
}

const lightTheme = 'ag-theme-balham';
const darkTheme = 'ag-theme-balham-dark';
const tableauTheme = 'ag-theme-tableau';
const alpineTheme = 'ag-theme-alpine';
const alpineDarkTheme = 'ag-theme-alpine-dark';

function updateAGGrid(gridOptions: GridOptions, gridState: GridState, options: CustomGridOptions, gridApi?: GridApi) {
  console.time('updateAGGrid');

  // update grid styles
  // @ts-ignore
  let env = window.tableau.extensions.environment;
  if (env.context === 'desktop' && env.tableauVersion.startsWith('2018')) {
    gridView.classList.add('flexbox-fixes');
  }
  // add a var for font family
  if (parseFloat(env.tableauVersion) >= 2021.4) {
    if (options.useTableauFont) {
      // @ts-ignore
      gridView.classList.add(window.tableau.ClassNameKey.Worksheet);
    } else {
      // @ts-ignore
      gridView.classList.remove(window.tableau.ClassNameKey.Worksheet);
    }
  }

  gridView.classList.remove(lightTheme, darkTheme, tableauTheme, alpineTheme, alpineDarkTheme);

  switch (options.theme) {
    case 'alpine-dark':
      gridView.classList.add(alpineDarkTheme);
      break;
    case 'alpine':
      gridView.classList.add(alpineTheme);
      break;
    case 'dark':
      gridView.classList.add(darkTheme);
      break;
    case 'light':
      gridView.classList.add(lightTheme);
      break;
    case 'tableau':
      gridView.classList.add(tableauTheme);
      break;
    default:
      gridView.classList.add(tableauTheme);
      break;
  }
  let settings = getSettings();
  // primary color like

  if (settings) {
    // @ts-ignore
    settings.themeConfig.oddRowBackgroundColor =
      settings.themeConfig.oddRowBackgroundColor ?? settings.themeConfig.backgroundColor;
    for (const [key, value] of Object.entries(settings.themeConfig)) {
      // backward compatibility: clear values from root node, otherwise we get messy code
      document.documentElement.style.removeProperty(
        `--ag-${key.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase()}`
      );
      // clear values from grid, otherwise we get messy code
      gridView.style.removeProperty(`--ag-${key.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase()}`);

      // if value present set it
      if (value) {
        gridView.style.setProperty(`--ag-${key.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase()}`, value);
      }
    }
    // Apply column group header styles
    // Create a style element
    const customStyleElement = document.createElement('style');
    customStyleElement.id = 'custom-AFT-AG-grid-styles';
    let customCssStyle = `/* Custom CSS Styles Generated Based on configuration */`;
    //Custom Header Font Size by adding a style to a preexisintg css class
    customCssStyle += `.ag-header-cell, .ag-header-group-cell {
      font-size: ${settings.themeConfig.headerFontSize || 'inherit'};
    }`;

    // Group Header Style
    for (const [columnGroupId, columnGroupConfig] of Object.entries(settings.columnGroupConfig)) {
      if (columnGroupConfig) {
        const uniqueCssClassname = `--ag-group-header-${columnGroupId.replace(/[^a-zA-Z0-9-_]/g, '')}`;

        customCssStyle += `
        .${uniqueCssClassname} {
          background-color: ${columnGroupConfig['ColumnGroupBackgroundColor']};
          color: ${columnGroupConfig['ColumnGroupTextColor']};
        }
        .${uniqueCssClassname}:hover {
          background-color: ${columnGroupConfig['ColumnGroupBackgroundColor']} !important;
        }
        .${uniqueCssClassname} .ag-header-icon {
          color: ${columnGroupConfig['ColumnGroupTextColor']};
        }`;
      }
    }
    // Column Header Style
    //TODO: Check if fieldname is unique for calculations
    const newColumnConfig = gridOptions.context.newColumnConfig as ConfigMap;
    for (const [columnId, columnConfig] of Object.entries(newColumnConfig)) {
      if (!columnConfig || !columnConfig?.color) continue;
      let fieldName = columnConfig.fieldName ?? columnId;
      const uniqueCssClassname = `ag-header-${removeAgg(fieldName).replace(/[^a-zA-Z0-9-_]/g, '')}`;
      if (!columnConfig.color.headerBackgroundColor && !columnConfig.color.headerFontColor) continue;
      customCssStyle += `
      .${uniqueCssClassname} {
        background-color: ${columnConfig.color.headerBackgroundColor || 'inherit'};
        color: ${columnConfig.color.headerFontColor || 'inherit'};
      }
      .${uniqueCssClassname}.ag-header-active:hover {
        background-color: ${columnConfig.color.headerBackgroundColor || 'inherit'} !important;
      }

      .${uniqueCssClassname} .ag-header-icon {
        color: ${columnConfig.color.headerFontColor || 'inherit'};
      }`;
    }
    if (customCssStyle) {
      // Delete old cystom style if it exists
      const oldElemToDelete = document.getElementById('custom-AFT-AG-grid-styles');
      if (oldElemToDelete) {
        document.head.removeChild(oldElemToDelete);
      }
      // Append styles to the style element
      customStyleElement.appendChild(document.createTextNode(customCssStyle));
      // Append the style element to the document's head
      document.head.appendChild(customStyleElement);
    }
  }

  if (options.showCompactMode) {
    gridView.classList.add('compact');
  } else {
    gridView.classList.remove('compact');
  }

  // Trick TS into thinking that the gridOptions are valid after creating the grid
  const gridCreated = !!gridApi && !gridApi.isDestroyed();
  if (!gridCreated) {
    const newGridApi = createGrid(gridView, gridOptions);
    setGridApi(newGridApi);

    if (newGridApi) {
      // check if aggFunc has changed in gridOptions and update the gridState with latest aggFuncs
      if (gridState.columnState) {
        if (!gridOptions.columnDefs) throw new Error('did not receive columnState');

        if (!gridState.pivotMode) {
          gridOptions.columnDefs.forEach((stateElement) => {
            if (!gridState.columnState) throw new Error('did not receive columnState');

            gridState.columnState.forEach((gridElement, index) => {
              if ('field' in stateElement && stateElement.field === gridElement.colId && gridState.columnState) {
                gridState.columnState[index].aggFunc = stateElement.aggFunc;
                if (stateElement.sort && gridElement.sort !== stateElement.sort) {
                  // ensure the columns with a sorting order are set to the correct order
                  // the rest of the columns will be as they were
                  gridState.columnState[index].sort = stateElement.sort;
                }
              }
            });
          });
        }
      }
      if (settings) {
        applyGridState(gridState, gridOptions, newGridApi, settings);
      }

      if (options.groupColumnsPanel) {
        const columnsToolPanel = newGridApi.getToolPanelInstance('columns');
        if (columnsToolPanel) {
          columnsToolPanel.setColumnLayout([
            {
              headerName: 'Measures',
              children: gridOptions.columnDefs?.filter((col) => isMeasure(col)),
            },
            {
              headerName: 'Dimensions',
              children: gridOptions.columnDefs?.filter((col) => !isMeasure(col)),
            },
          ]);
        }
      }
    }
  } else {
    if (options.updateColumnDefs) {
      // make ts happy
      if (!gridOptions.columnDefs) throw new Error('did not receive columnDefs');
      //TODO: Double check this
      gridApi.setGridOption('columnDefs', gridOptions.columnDefs);
    }

    // updateRowData() or deltaRowDataMode=true are deprecated
    // Note:
    // when using GridApi.updateRowData() or deltaRowDataMode=true ag-grid
    // keeps the current filters/storting and grouping automatically
    // we don't have a reliable unique identifier for each row so it is difficult
    // to create the transaction required to make that work.
    // therefore we just store and restore the filter and sorting after setting the row data.
    // grouping is restored automatically because we have set rememberGroupStateWhenNewData=true
    // more information: https://www.ag-grid.com/javascript-grid-data-update/

    // store current grid state
    let filterModel = gridApi.getFilterModel();

    // make ts happy
    if (!gridOptions.rowData) throw new Error('did not receive rowData');

    gridApi.setGridOption('rowData', gridOptions.rowData);

    // restore previous grid state
    gridApi.setFilterModel(filterModel);
    EventBus.triggerEvent('startFetching');
  }
  // @ts-ignore
  setExportButton(settings);
  console.timeEnd('updateAGGrid');
}

// @ts-ignore
let unregisterExportEventListeners: Array<any> = [];

function createClickEventListener(
  element: HTMLElement,
  handler: (this: HTMLElement, ev: MouseEvent) => any
): () => void {
  element.addEventListener('click', handler);
  return () => element.removeEventListener('click', handler);
}

function exportButton() {
  document.querySelectorAll('.button-container').forEach((e) => e.remove());

  const buttonContainer = document.createElement('div');
  buttonContainer.className = 'button-container';
  let buttons: Array<HTMLElement> = [];
  const button = document.createElement('button');
  button.id = 'export-button';
  button.className =
    'flex justify-between items-center rounded-lg bg-background text-black w-40 text-left  px-4 py-1.5 my-1.5 mx-4 font-karla border border-gray-400';

  let text = document.createElement('span');
  text.textContent = 'Export';

  button.appendChild(text);

  const chevron = document.createElement('img');
  chevron.src = './icons/chevron.svg';
  chevron.id = 'chevron';
  button.appendChild(chevron);

  let closeTimeout;
  const dropdown = document.createElement('ul');
  dropdown.id = 'dropdown';
  dropdown.className =
    'absolute py-1 bg-background font-karla rounded-lg text-black left-0 mt-2 hidden w-40 shadow-lg z-50 border border-gray-200';

  const items = [{ text: 'Export to Excel' }, { text: 'Export to CSV' }, { text: 'Export to PDF' }];

  items.forEach((item) => {
    const a = document.createElement('a');
    a.className = 'px-4 py-2 w-full cursor-pointer hover:bg-accent';
    a.textContent = item.text;

    dropdown.appendChild(a);
    buttons.push(a);
  });

  button.onmouseenter = function (event: MouseEvent) {
    clearTimeout(closeTimeout); // Clear any existing timeout
    function openDropdown(event, dropdownID) {
      let element = event.target;

      createPopper(element, document.getElementById(dropdownID)!, {
        placement: 'top-start',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 6],
            },
          },
        ],
      });
      addOpenDropdownClasses();
    }
    openDropdown(event, 'dropdown');
  };

  button.onmouseleave = function () {
    closeTimeout = setTimeout(() => {
      removeOpenDropdownClasses();
    }, 300);
  };

  dropdown.onmouseover = function () {
    clearTimeout(closeTimeout);
    addOpenDropdownClasses();
  };
  dropdown.onmouseleave = function () {
    closeTimeout = setTimeout(() => {
      removeOpenDropdownClasses();
    }, 300);
  };

  function addOpenDropdownClasses() {
    document.getElementById('dropdown')?.classList.remove('hidden');
    document.getElementById('dropdown')?.classList.add('block');
    document.getElementById('export-button')?.classList.add('border-primary');
    document.getElementById('chevron')?.classList.add('rotate-180');
  }
  function removeOpenDropdownClasses() {
    document.getElementById('dropdown')?.classList.remove('block');
    document.getElementById('dropdown')?.classList.add('hidden');
    document.getElementById('export-button')?.classList.remove('border-primary');
    document.getElementById('chevron')?.classList.remove('rotate-180');
  }

  buttonContainer.appendChild(button);
  buttonContainer.appendChild(dropdown);
  return { buttonContainer, buttons };
}

function setExportButton(settings: Settings) {
  let { buttonContainer, buttons } = exportButton();
  const statusBarRight = document.getElementsByClassName('ag-status-bar-right')[0];
  const paginationBar = document.getElementsByClassName('ag-paging-panel ')[0];
  // @ts-ignore
  unregisterExportEventListeners.forEach((listener) => listener());
  unregisterExportEventListeners = [];

  if (!settings.floatingExportButton || !buttons || settings.floatingExportButtonsType.length === 0) {
    buttonContainer.style.display = 'none';
    return;
  }

  if (settings.showStatusBar && statusBarRight) {
    statusBarRight.append(buttonContainer);
  } else if (settings.enablePagination && paginationBar) {
    paginationBar.append(buttonContainer);
  } else {
    buttonContainer.classList.add('floating-buttons');
    const grid = getElement('grid');
    grid.append(buttonContainer);
  }

  buttons[0].style.display = settings.floatingExportButtonsType.includes('excel') ? 'inline-block' : 'none';
  buttons[1].style.display = settings.floatingExportButtonsType.includes('csv') ? 'inline-block' : 'none';
  buttons[2].style.display = settings.floatingExportButtonsType.includes('pdf') ? 'inline-block' : 'none';

  async function onExcelExportButtonClicked() {
    if (gridApi) {
      await exportExcel(gridApi);
    }
  }

  async function onCSVExportButtonClicked() {
    if (gridApi) {
      await exportCSV(gridApi);
    }
  }

  async function onPDFExportButtonClicked() {
    if (gridApi) {
      await exportPDF(gridApi);
    }
  }

  unregisterExportEventListeners.push(createClickEventListener(buttons[0], onExcelExportButtonClicked));
  unregisterExportEventListeners.push(createClickEventListener(buttons[1], onCSVExportButtonClicked));
  unregisterExportEventListeners.push(createClickEventListener(buttons[2], onPDFExportButtonClicked));
}

/**
 * Checks if there are any formulas, if so displays the remove formulas message in the error page.
 */
export const initialseFormulaError = (stateParameter: string | undefined) => {
  if (!stateParameter) {
    return;
  }
  removeFormulasContainer.style.display = 'block';
  removeFormulasButton.addEventListener('click', async () => {
    const parameterValue = await getParameterValue(stateParameter);
    if (parameterValue && parameterValue.formulaColumns && parameterValue.formulaColumns.length > 0) {
      parameterValue.formulaColumns = [];
      await setParameterValue(stateParameter, JSON.stringify(parameterValue));
      removeFormulasContainer.style.display = 'none';
      initializeApp();
    } else {
      removeFormulasMessage.style.display = 'block';
      removeFormulasMessage.innerText = 'No formulas to remove';
      setTimeout(() => {
        removeFormulasMessage.innerText = '';
        removeFormulasMessage.style.display = 'none';
      }, 4000);
    }
  });
};
