import { makeCellClass } from '@/grid/mapping/makeCellClass';
import { compose } from '@/utils/functional';
import { createValueGetter } from '@/utils/grid';
import { RowClassParams, RowStyle } from 'ag-grid-community';
import { Settings } from '@/settings';
import { isCalculation } from '@/grid/mapping/utils';
import { createBackgroundStyler } from '@/grid/mapping/createStyler';
import { BaseColDefParams } from 'ag-grid-community/dist/types/core/entities/colDef';

export function applyRowClass() {
  return (params: RowClassParams) => {
    let classes: Array<string> = [];
    const isGrandTotal = params.node.level === -1 || params.node.rowPinned === 'top';
    const isSubTotal = params.node.level !== -1 && params.node.group && params.node.footer;
    if (isGrandTotal) {
      classes.push('total-group-row');
    }

    if (isSubTotal) {
      classes.push('subtotal-group-row');
    }

    return classes;
  };
}

export function applyRowStyle(settings: Settings) {
  return (params: RowClassParams) => {
    let style: RowStyle = {};

    const isGrandTotal = params.node.level === -1 || params.node.rowPinned === 'top';
    const isSubTotal = params.node.level !== -1 && params.node.group && params.node.footer;
    if (isGrandTotal) {
      style.fontWeight = 'bold';
      style.backgroundColor = settings.themeConfig.totalsBackgroundColor ?? style.backgroundColor;
      style.color = settings.themeConfig.totalsTextColor ?? style.color;
    }

    if (isSubTotal) {
      style.fontWeight = 'bold';
      style.backgroundColor = settings.themeConfig.subtotalBackgroundColor ?? style.backgroundColor;
      style.color = settings.themeConfig.subtotalTextColor ?? style.color;
    }

    if (settings.rowColorColumn) {
      let config = settings.columnConfig[settings.rowColorColumn];
      if (config) {
        const bgColorClasses = compose(
          createBackgroundStyler(config.color, {}, config.colorCellBackground),
          createValueGetter(settings.rowColorColumn)
        )(params);
        if (bgColorClasses) {
          style = { ...style, ...bgColorClasses };
        }
      }
    }

    return style;
  };
}

export function getIndentClass(params: BaseColDefParams) {
  let classes: Array<string> = [];
  var indent = 0;
  var node = params.node;

  while (node && node.parent) {
    indent++;
    node = node.parent;
  }
  classes.push('indent-' + indent);
  return classes;
}

export function getHeaderCellClass(settings: Settings) {
  return (params) => {
    if (!params.value) {
      return 'exportStyle';
    }
    let classes: Array<string> = [];
    const indentClasses = getIndentClass(params);
    classes.push(...indentClasses);

    const isGrandTotalCell = params.node.level === -1 || params.node.rowPinned === 'top';
    if (isGrandTotalCell) {
      classes.push('total-group-row-cell');
      return classes;
    }

    let name: string = '';
    const showRowGroup = params.column.getColDef().showRowGroup;
    if (params.node.group && showRowGroup && params.node.field) {
      name = typeof showRowGroup === 'boolean' ? showRowGroup.toString() : params.node.field;
    } else if (params.node.parent?.field) {
      name = params.node.parent.field;
    }

    const config = settings.columnConfig[name];
    const columns = params.context?.tableauColumns;
    const column = columns?.find((c) => (isCalculation(c) ? c.index === name : c.fieldName === name));

    const cellClasses = makeCellClass(config, column, settings, params);
    if (cellClasses) {
      classes.push(...cellClasses);
    }

    return classes;
  };
}
