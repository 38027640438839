import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community';

export default class EmojiCellRenderer implements ICellRendererComp {
  eGui!: HTMLElement;

  init({ value }: ICellRendererParams): void {
    this.eGui = document.createElement('div');

    if (!value) return;

    if (value.substr(0, 2) === 'U+') {
      let code = value.substr(value.length - 5);
      this.eGui.innerHTML = '&#x' + code;
    } else {
      this.eGui.innerHTML = value;
    }
  }

  getGui(): HTMLElement {
    return this.eGui;
  }

  refresh(): boolean {
    return true;
  }
}
