import { DataValue, Column } from '@tableau/extensions-api-types';
import parseDate from 'date-fns/parse';

export type ValueParser = (dataValue: DataValue) => string | number | Date | null;

export function createValueParser(column: Column): ValueParser {
  switch (column.dataType) {
    case 'date':
    case 'date-time':
      // dates are received as strings in the format "YYYY-MM-DD [HH:MM:SS]"
      return (dataValue) => {
        if (dataValue.value === '%null%') return null;
        return parseDate(dataValue.value);
      };

    case 'float':
    case 'int':
      return (dataValue) => dataValue.value;

    case 'spatial': // @todo: does this need special handling?
    case 'bool':
    case 'string':
    default:
      return (dataValue) => (dataValue.formattedValue ? dataValue.formattedValue : dataValue.value);
  }
}
